import APIService from './base/APIService.js';
import { of } from 'rxjs';
import { getBrickDescription, getBrickCode } from '../../../common/helpers/Common.js';

export default class SchwarzMvpAPIService extends APIService {

    constructor() {
        super()
        this.MODE = process.env.REACT_APP_MODE
        switch (this.MODE) {
            case "prod":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL_PROD
                break;

            case "test":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL
                break

            case "mvp":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL_MVP
                break
            case "lidl":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL_LIDL
                break

            default:
                break;
        }
    }
    DEFAULT_TARGET_MARKET = '276'

    getProductDetailsFromEs(productId, origin, contentLanguage, labelLanguage) {
        return this.postWithUIToken('get-product-details-from-es/', JSON.stringify({ productId: productId, origin: origin, 
            contentLanguage: contentLanguage, labelLanguage: labelLanguage }));
    }

    exportMarkantDataToExcel(itemDetails, requesterEmail, countryCode, supplierMasterData, language) {
        return this.postWithUIToken('export-markant-form-data/', JSON.stringify({ itemDetails: itemDetails, requesterEmail: requesterEmail, countryCode: countryCode, supplierMasterData: supplierMasterData, language: language}));
    }

    sendEmail(itemDetails, requesterEmail, countryCode, mailTo, language) {
        return this.postWithUIToken('send-email-markant-form-data/', JSON.stringify({ itemDetails: itemDetails, requesterEmail: requesterEmail, countryCode: countryCode, mailTo: mailTo, language: language}));
    }

    saveData(itemDetails, countryCode){
        return this.postWithUIToken('_init-ap/', JSON.stringify({itemDetails: itemDetails, tm: countryCode }) )
    }

    getProductDetails(xml, origin) {
        return this.postWithUIToken('get-product-details/', JSON.stringify({ xmlContent: xml, origin: origin }));
    }

    getGPCData(gpcCategoryCode) {
        return this.postWithUIToken('get-gpc-data/', JSON.stringify({ gpcCode: gpcCategoryCode }));
    }

    getCompleteLangGPCData() {
        return this.getWithUIToken('get-all-gpc-data/');
    }


    downloadProductPdf(documentUrl) {
        return this.postWithUIToken('download-item-pdf/', JSON.stringify({ documentUrl: documentUrl}));
    }

    getCinXML(json) {
        return this.postWithUIToken('fetch_cin', JSON.stringify({ json: json}));
    }

    downloadOriginalMessage(productId, source) {
        return this.postWithUIToken('download-original-message/', JSON.stringify({id: productId, source: source}))
    }

    getTargetMarkets() {
        let query = {
            aggs: {
                targetMarkets: {
                    terms: {
                        field: "targetMarket.targetMarketCountryCode",
                        size: 10000
                    }
                }
            }
        }
        return this.postWithUIToken('get-target-markets/', JSON.stringify(query));
    }

    getDataCount() {
        return this.getWithUIToken('get-sources/');
    }

    getDashboardData() {
        return this.getWithUIToken('get-dashboard-data/');
    }

    getValidTargetMarketsForMarkant() {
        return of(new Response('{"data":["all", "276", "616", "203", "100", "703", "642", "498", "191"]}', { status: 200 })).toPromise();
    }

    /**
    * GDSN Subscription-related
    */

    getValidTargetMarketsForGdsnSubscriptions() {
        return of(new Response('{"data":["276"]}', { status: 200 })).toPromise();
    }

    getValidSubscriberGlns() {
        return of(new Response('{"data":["7610029070019"]}', { status: 200 })).toPromise();
    }

    createGdsnSubscription(gdsnSubscription) {
        let path = 'create-subscription/';
        return this.postWithUIToken(path, JSON.stringify(gdsnSubscription));
    }

    deleteGdsnSubscription(gdsnSubscription) {
        let path = 'delete-subscription/';
        return this.deleteWithUIToken(path, JSON.stringify(gdsnSubscription));
    }

    deleteApassRequest(apassRequestId) {
        let path = 'delete-apass-request/';
        return this.deleteWithUIToken(path, JSON.stringify({ apassRequestId: apassRequestId}));
    }

    downloadApassExport(apassExportId) {
        let path = 'apass-download-request/';
        return this.postWithUIToken(path, JSON.stringify({ apassExportId: apassExportId}));
    }

    exportData(gtinList, tm_list) {
        return this.postWithUIToken('export-data/', JSON.stringify({ gtinList: gtinList, tm_list}));
    }

    getKauflandArticlepass(productId) {
        return this.postWithUIToken('export-kaufland-artikel-pass/', JSON.stringify({id: productId}))
    }
    
    excelFileUpload(formData) {
        return this.fileUploadWithUIToken('excel-file-upload/', formData)
    }

    getArticlePassExportList(useFilters, ekg, gtin, targetMarket, gln, pageNumber, perPage,
        subscriberGln = null, isDeleted = null, from = null, to = null) {
        
        let path = 'apass-export-list/?';
        const params = {
            pageNumber,
            perPage,
            subscriberGln: useFilters ? subscriberGln : undefined,
            isDeleted: useFilters ? isDeleted : undefined,
            ekg: useFilters ? ekg : undefined,
            gtin: useFilters ? gtin : undefined,
            targetMarket: useFilters ? targetMarket : undefined,
            gln: useFilters ? gln : undefined,
            from: useFilters ? from?.toISOString() : undefined,
            to: useFilters ? to?.toISOString() : undefined,
        };

        path += Object.entries(params)
            .filter(([key, value]) => value !== null && value !== undefined && value !== '')
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

        return this.getWithUIToken(path);
    }

    getArticlePassRequest(useFilters, ekg, gtin, targetMarket, gln, pageNumber, perPage,
        subscriberGln = null, isDeleted = null, from = null, to = null) {
        
        let path = 'apass-export-request/?';
        const params = {
            pageNumber,
            perPage,
            subscriberGln: useFilters ? subscriberGln : undefined,
            isDeleted: useFilters ? isDeleted : undefined,
            ekg: useFilters ? ekg : undefined,
            gtin: useFilters ? gtin : undefined,
            targetMarket: useFilters ? targetMarket : undefined,
            gln: useFilters ? gln : undefined,
            from: useFilters ? from?.toISOString() : undefined,
            to: useFilters ? to?.toISOString() : undefined,
        };

        path += Object.entries(params)
            .filter(([key, value]) => value !== null && value !== undefined && value !== '')
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

        return this.getWithUIToken(path);
    }
    
    /**
     * search related
     */

    /**
     * Elasticsearch - adapted from
     */

    search(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches) {
        let keywords = keyword.split(' ')
        let negationKeywords = []
        let filterAttributesList = []
        let searchQuery = ''
        let originQuery = ''
        let targetMarketQuery = ''
        for (let i = 0; i < keywords.length; i++) {
            if (keywords[i][0] === '-') {
                if (keywords[i].length > 1) {
                    negationKeywords.push(keywords[i])
                }
            }
            else {
                if (keywords[i].length > 0) {
                    if (keywords[i].includes(':')) {
                        if (keywords[i].split(':')[1].length > 0) {
                            let filterAttributePair = keywords[i].split(':')
                            let filterCondition = {}
                            let attribute = filterAttributePair[1].replace('-', ' * ')
                            filterCondition = this.mapFilterAttributes(filterAttributePair[0], attribute, exactSearch)
                            filterAttributesList.push(filterCondition)
                            if (exactSearch) {
                                searchQuery = searchQuery + ' (' + filterAttributePair[1] + ') ' + logicalOperator
                            } else {
                                searchQuery = searchQuery + ' (*' + filterAttributePair[1] + '*) ' + logicalOperator
                            }
                        }
                    }
                    else {
                        if (exactSearch) {
                            searchQuery = searchQuery + ' (' + keywords[i] + ') ' + logicalOperator
                        } else {
                            searchQuery = searchQuery + ' (*' + keywords[i] + '*) ' + logicalOperator
                        }
                    }
                }
            }
        }
        if (logicalOperator === 'AND') {
            searchQuery = searchQuery.slice(0, -4)
        } else if (logicalOperator === 'OR') {
            searchQuery = searchQuery.slice(0, -3)
        }
        for (let i = 0; i < negationKeywords.length; i++) {
            searchQuery = searchQuery + ' ' + negationKeywords[i]
        }
        for (let i = 0; i < targetMarketSwitches.length; i++) {
            for (let key in targetMarketSwitches[i]) {
                if (targetMarketSwitches[i][key] === true) {
                    targetMarketQuery = targetMarketQuery + ' OR ' + key
                }
            }
        }
        for (let i = 0; i < originSwitches.length; i++) {
            for (let key in originSwitches[i]) {
                if (originSwitches[i][key] === true) {
                    originQuery = originQuery + ' OR ' + key
                }
            }
        }
        originQuery = originQuery.substring(4)
        targetMarketQuery = targetMarketQuery.substring(4)
        let isConsumerUnitOnly_query = {
            'query_string': {
                 'query': isConsumerUnit,
                 'fields': ['isTradeItemAConsumerUnit']
            }
        }
        let query = {
            query: {
                bool: {
                    must: [
                        {
                            query_string: {
                                query: searchQuery,
                                fields: [
                                    "gtin",
                                    "brandOwner.*",
                                    "manufacturerOfTradeItem.*",
                                    "informationProviderOfTradeItem.*",
                                    "gpcBrickCode",
                                    "nextLowerLevelTradeItemInformation.childTradeItem.gtin",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.descriptionShort.*",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.functionalName.*",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.tradeItemDescription.*",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.*",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.regulatedProductName.*",
                                    "tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.additionalTradeItemDescription.*"
                                ]
                            }
                        },
                        {
                            'query_string': {
                                'query': descriptorCode,
                                fields: ['tradeItemUnitDescriptorCode.__value__']
                            }
                        },
                        {
                            'query_string': {
                                'query': originQuery,
                                fields: ['source']
                            }
                        },
                        {
                            'query_string': {
                                'query': targetMarketQuery,
                                fields: ['targetMarket.targetMarketCountryCode']
                            }
                        }
                    ]
                }
            },
            'from': offset,
            'size': size
        }
        if(isConsumerUnit){
            query['query']['bool']['must'].push(isConsumerUnitOnly_query)
        }
        if (filterAttributesList.length > 0) {
            let condition = ''
            if (logicalOperator === 'AND') {
                condition = 'must'
            } else if (logicalOperator === 'OR') {
                condition = 'should'
            }
            let filterObject = {}
            filterObject['bool'] = {}
            filterObject['bool'][condition] = []
            for (let i = 0; i < filterAttributesList.length; i++) {
                filterObject['bool'][condition].push(filterAttributesList[i])
            }
            query['query']['bool']['must'].push(filterObject)
        }
        return this.post('_ui_search', JSON.stringify(query));
    }

    mapFilterAttributes(filter, attribute, exactSearch) {
        let filterCondition = {}
        if (filter === 'productDescription') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.descriptionShort.__value__']
        }
        else if (filter === 'productName') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.functionalName.__value__']
        }
        else if (filter === 'manufacturer') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['manufacturerOfTradeItem.partyName', 'informationProviderOfTradeItem.partyName']
        }
        else if (filter === 'gln') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['manufacturerOfTradeItem.gln', 'informationProviderOfTradeItem.gln']
        }
        else if (filter === 'gpc') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['gpcBrickCode', 'gpcBrickDescriptionDe', 'gpcClassCode', 'gpcClassDescriptionDe', 'gpcFamilyCode', 'gpcFamilyDescriptionDe', 'gpcSegmentCode', 'gpcSegmentDescriptionDe']
        }
        else if (filter === 'brandName') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName']
        }
        else if (filter === 'targetMarket') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['targetMarket.targetMarketCountryCode']
        }
        else if (filter === 'isWithdrawn') {
            filterCondition['term'] = {
                'isWithdrawn': attribute
            }
        }
        else {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = [filter]
        }
        return filterCondition
    }


    handleGPCManufacturerFilterButtons(theFilterList, originalRecordList, completeGPCData, language) 
    {
        const theManufacturers = theFilterList['manufacturer']
        const theGPCList = theFilterList['gpc']
        let brickDescription = ''
        let gpcBrickCode = ''

        let recordList = originalRecordList.filter(function(product) {
            gpcBrickCode = getBrickCode(product)             
            brickDescription = getBrickDescription(completeGPCData,language,gpcBrickCode)               
            const gpcCode = gpcBrickCode + ' - ' + brickDescription
            if( 'manufacturerOfTradeItem' in product && 'partyName' in product['manufacturerOfTradeItem'][0] ) {
                return theManufacturers.includes(product['manufacturerOfTradeItem'][0].partyName) && theGPCList.includes(gpcCode)
            } else if('informationProviderOfTradeItem' in product && 'partyName' in product['informationProviderOfTradeItem']) {
                return theManufacturers.includes(product['informationProviderOfTradeItem'].partyName) && theGPCList.includes(gpcCode)
            }
            return true
        })
        return { records: recordList };
    }
}