import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { CustomerContext } from "../common/CustomerContext.js";
import { exportProductJSON, exportProductXML, getCookie } from '../common/helpers/Common'
import LinearProgress from '@material-ui/core/LinearProgress';


class MenuItemComponent extends React.Component {
  static contextType = CustomerContext;
  constructor(props) {
    super(props);
    this.state = {
      product: props.product
    };
  }

  handleOptionClick = (option) => {
    let snackBarMessage = '';
    switch (option) {
      case 'json':
          snackBarMessage = `Trade item JSON ${this.props.product['gtin']}`
          this.props.handleSnackBarOpen(snackBarMessage)
          exportProductJSON(this.props.product)
          break;
      case 'xml':
          snackBarMessage = `Trade item XML ${this.props.product['gtin']}`
          this.props.handleSnackBarOpen(snackBarMessage)
          exportProductXML(this.props.product)
          break;
      case 'cin':
          this.props.downloadCIN(this.props.recordIndex)
          break;
      case 'infoxml':
          this.props.downloadInformaticaXML(this.props.recordIndex)
          break;
      case 'kap':
          this.props.downloadKauflandArticlepassMoreButton(this.props.recordIndex)
          break;
      case 'tradeItemHierarchy':
          this.props.onTradeItemHierarchyProduct(this.props.product)
          this.props.toggleTradeItemHierarchyModal()
          this.props.onClose()
          break;   
      case 'originalMessage':
          this.props.downloadOriginalMessage(this.props.recordIndex)
          break;   
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {process.env.REACT_APP_SOURCE === 'aldi' && 
          <div style={{width: "100%"}}>
            <MenuItem disabled={this.props.product.disableInformaticaXMLMenuItem} onClick={() => this.handleOptionClick('infoxml')}>
              <div style={{fontSize: "14px"}}>Informatica XML</div>
            </MenuItem>
            <div hidden={this.props.product.hideInformaticaXMLLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) &&
          <div style={{width: "100%"}}>
            <MenuItem disabled={this.props.product.disableCINMenuItem} onClick={() => this.handleOptionClick('cin')}>
              <div style={{fontSize: "14px"}}>CIN XML</div>
            </MenuItem>
            <div hidden={this.props.product.hideCINLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) &&
          <div style={{width: "100%"}}>
            <MenuItem disabled={this.props.product.disableKArticlePassMenuItem} onClick={() => this.handleOptionClick('kap')}>
              <div style={{fontSize: "14px"}}>Kaufland ArticlePass</div>
            </MenuItem>
            <div hidden={this.props.product.hideKArticlePassLinearProgress}><LinearProgress /></div>
          </div>
        }
        <MenuItem onClick={() => this.handleOptionClick('json')}>
          <div style={{fontSize: "14px"}}>Trade item JSON</div>
        </MenuItem>
        <MenuItem onClick={() => this.handleOptionClick('xml')}>
           <div style={{fontSize: "14px"}}>Trade item XML</div>
        </MenuItem>
        <MenuItem onClick={() => this.handleOptionClick('tradeItemHierarchy')}>
          <div style={{fontSize: "14px"}}>Trade item Hierarchy</div>
        </MenuItem>
        {!['OFF'].includes(this.props.product['source']) && getCookie('is_message_download_allowed') == 'true' &&
          <div style={{width: "100%"}}>
            <MenuItem disabled={this.props.product.disableMessageDownloadMenuItem} onClick={() => this.handleOptionClick('originalMessage')}>
              <div style={{fontSize: "14px"}}>Original Message</div>
            </MenuItem>
            <div hidden={this.props.product.hideMessageDownloadLinearProgress}><LinearProgress /></div>
          </div>
        }
      </div>
    );
  }
}

export default (MenuItemComponent);
