/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createTheme, MuiThemeProvider, ThemeProvider } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import i18next from 'i18next';
import Pagination from "material-ui-flat-pagination";
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { Link, withRouter } from 'react-router-dom';
import XMLViewer from 'react-xml-viewer';
import { Card, CardBody, CardHeader, CardTitle, Col, Form, Input, Label, Table } from "reactstrap";
import { compose } from 'redux';
import '../assets/products.css';
import { CustomerContext } from "../common/CustomerContext.js";
import { traverseJson } from '../common/helpers/JSONHelper';
import { sendEmail } from '../common/utility/SendEmail';
import Dashboard from './dashboard/Dashboard';
import FilterButtons from './FilterButtons';
import GdsnSubscriptionModal from './gdsn-subscription/GdsnSubscriptionModal';
import ChangePasswordModal from './modals/ChangePasswordModal';
import EuvinoSubscriptionModal from './modals/EuvinoSubscriptionModal';
import ExportModal from './modals/ExportModal';
import ProductModal from './modals/ProductModal';
import GPCModal from './modals/GPCModal';
import ReleaseModal from './modals/ReleaseModal';
import { saveAs } from "file-saver";
import { ekgCodeList } from '../common/data/ekg_code_list';
import { getCookie, openInNewTab, gpcLocalization, getBrickCode, 
         getGPCDescriptionWithBrickCheck, keyExists, translateKey, 
         searchQueryFilterItems, exportProductJSON, exportProductXML,
         getDataSourceLabel, getDistinctValues
       } from '../common/helpers/Common.js';
import DataRequestModal from './modals/DataRequestModal';
import ModalIcons from './modals/ModalIcons';
import CircularProgress from '@material-ui/core/CircularProgress';
import {palette} from '../assets/Palette';
import SearchQueryFilter from './SearchQueryFilter';
import TradeItemHierarchyModal from './modals/TradeItemHierarchyModal.jsx';
import MenuItemComponent from './MenuItems';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { countryCodes } from '../common/data/country_code_list';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const xml2js = require('xml2js');
const defaultLanguage = 'en'

const customTheme = {
  "attributeKeyColor": "#ff0000",
  "attributeValueColor": "#3333ff",
  "commentColor": "#00802b",
  "tagColor": "#6600cc"
}


const theme = createTheme({
  palette: palette
});

const labelFontSize = createTheme({
  palette: palette,
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: '0.75rem'
      }
    }
  }
});

const footerStyle = {
  flexShrink: 0,
  textAlign: "right",
  backgroundColor: "#f2f2f2",
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%'
};

// used for delay in API call
const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

class Products extends React.Component {
  static contextType = CustomerContext;
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      size: '25',
      keyword: '',
      offset: 0,
      total: 0,
      pageNumber: 1,
      isTextboxEmpty: true,
      showLoader: false,
      noRecordsFound: false,
      toggleXMLModal: false,
      toggleGPCModal: false,
      releaseModal: false,
      toggleChangePasswordModal: false,
      xmlContent: '',
      jsonContent: '',
      originalJson: {},
      logicalOperator: 'AND',
      childTradeItemRadio: 'base',
      isBaseUnit: false,
      isConsumerUnit: false,
      dataNature: true,
      trustBox: true,
      gdsn: true,
      markant: true,
      origin: ['datanature', 'trustbox', 'gdsn', 'markant'],
      datanatureCount: 0,
      trustboxCount: 0,
      gdsnCount: 0,
      markantCount: 0,
      index: 0,
      fileName: '',
      isSpacebar: false,
      segment: '',
      family: '',
      class: '',
      brick: '',
      treeView: true,
      treeList: [],
      exactSearch: false,
      listedFilter: false,
      imageModal: false,
      documentModal: false,
      cicModal: false,
      tradeItemRecordsModal: false,
      isTradeItemHierarchyModalOpen: false,
      tradeItemHierarchyProduct: null, 
      tradeItemWithdrawalModal: false,
      tableModal: true,
      productDetails: {},
      dataSource: '',
      customAttributes:{},
      disableImageButton: true,
      disableDocumentButton: true,
      targetMarkets: [],
      targetMarketSwitches: [],
      targetMarketSwitchesOld: [],
      sources: [],
      sourceSwitches: [],
      dashboardData: {},
      isDashboardLoadingComplete: false,
      inputValue: '',
      gpcFilterList: [],
      completeGPCData: {},
      manufacturerFilterList: [],
      originalRecordList: [],
      defaultTargetMarket: '',
      listedDict: {},
      listedIndexes: [],
      selectAllItems: false,
      idDict: {},
      isEuvino: false,
      isLidl: false,
      isKaufland: false,
      areOtherOrigions: true,
      // gdsn subscription
      isGdsnSubscriptionModalDisplayed: false,
      isEuvinoSubscriptionModalDisplayed: false,
      // Export
      isExportModalDisplayed: false,
      userRights: {},
      isUserListingReadOnly: false,
      language: 'de',
      showLanguageDropdown: false,
      anchorElXmlDownload: null,
      schwarzSelectedItems:[],
      schwarzSelectedItemList:[],
      markantFormCountryCode: '',
      toggleDataRequestModal: false,
      itemSelectAlert: false,
      productId:'',
      productDetailsLanguage: '',
      searchQueryFilter:'all',
      searchInputPlaceHolder: 'Search in All Fields',
      //Search result item snack bar
      snackBarOpen: false,
      snackBarMessage: '',
      snackBarDuration: '',
      snackBarSeverity: 'success'
    };

    this.onSearchQueryFilterChange = this.onSearchQueryFilterChange.bind(this)
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleLogicalOperatorRadioChange = this.handleLogicalOperatorRadioChange.bind(this);
    this.handleChildTradeItemRadioChange = this.handleChildTradeItemRadioChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.generateXMLContent = this.generateXMLContent.bind(this);
    this.generateMigrosXMLContent = this.generateMigrosXMLContent.bind(this);
    this.generateOtherOriginsXMLContent = this.generateOtherOriginsXMLContent.bind(this);
    this.generateEuvinoContent = this.generateEuvinoContent.bind(this);
    this.toggleGPCModalSearch = this.toggleGPCModalSearch.bind(this);
    this.initiateDataRequestModal = this.initiateDataRequestModal.bind(this);
    this.handleDataRequestModalClose = this.handleDataRequestModalClose.bind(this);
    this.toggleChangePassword = this.toggleChangePassword.bind(this);
    this.downloadXML = this.downloadXML.bind(this);
    this.downloadInformaticaXML = this.downloadInformaticaXML.bind(this);
    this.downloadJSON = this.downloadJSON.bind(this);
    this.downloadCIN = this.downloadCIN.bind(this);
    this.downloadKauflandArticlepassMoreButton = this.downloadKauflandArticlepassMoreButton.bind(this);
    this.downloadOriginalMessage = this.downloadOriginalMessage.bind(this);
    this.logout = this.logout.bind(this);
    this.callSearchAPI = this.callSearchAPI.bind(this);
    this.migrosSearchAPI = this.migrosSearchAPI.bind(this);
    this.schwarzSearchAPI = this.schwarzSearchAPI.bind(this);
    this.schwarzMvpSearchAPI = this.schwarzMvpSearchAPI.bind(this);
    this.saveGPCSettings = this.saveGPCSettings.bind(this);
    this.modifyFilter = this.modifyFilter.bind(this);
    this.getDataCount = this.getDataCount.bind(this);
    this.generateSourceSwitches = this.generateSourceSwitches.bind(this);
    this.handleSourceSwitches = this.handleSourceSwitches.bind(this);
    this.detectEnterKey = this.detectEnterKey.bind(this);
    this.handleOnlyBaseUnitChange = this.handleOnlyBaseUnitChange.bind(this);
    this.handleOnlyConsumerUnitChange = this.handleOnlyConsumerUnitChange.bind(this);
    this.traverseProductDetails = this.traverseProductDetails.bind(this);
    this.toggleTree = this.toggleTree.bind(this);
    this.handleExactSearchChange = this.handleExactSearchChange.bind(this);
    this.handleListedFilterChange = this.handleListedFilterChange.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.toggleDocumentModal = this.toggleDocumentModal.bind(this);
    this.toggleTableModal = this.toggleTableModal.bind(this);
    this.toggleCicModal = this.toggleCicModal.bind(this);
    this.toggleTradeItemRecordsModal = this.toggleTradeItemRecordsModal.bind(this);
    this.toggleTradeItemHierarchyModal = this.toggleTradeItemHierarchyModal.bind(this);
    this.onTradeItemHierarchyProduct = this.onTradeItemHierarchyProduct.bind(this);
    this.toggleTradeItemWithdrawalModal = this.toggleTradeItemWithdrawalModal.bind(this);
    this.getProductDetailsFromEs = this.getProductDetailsFromEs.bind(this);
    // @TODO Remove below line after decommissioning the Schwarz platform
    this.generateProductDetails = this.generateProductDetails.bind(this);
    this.generateModal = this.generateModal.bind(this);
    this.generateMigrosModal = this.generateMigrosModal.bind(this);
    this.generateOtherOriginsModal = this.generateOtherOriginsModal.bind(this);
    this.generateStandardProductModal = this.generateStandardProductModal.bind(this);
    this.generateTargetMarketSwitches = this.generateTargetMarketSwitches.bind(this);
    this.updateTargetMarketSwitches = this.updateTargetMarketSwitches.bind(this);
    this.handleGPCManufacturerFilterButtons = this.handleGPCManufacturerFilterButtons.bind(this);
    // this.generateDashboard = this.generateDashboard.bind(this);
    this.getDashboardData = this.getDashboardData.bind(this);
    this.clickExampleData = this.clickExampleData.bind(this);
    // this.generateFilterButtons = this.generateFilterButtons.bind(this);

    this.toggleReleaseModal = this.toggleReleaseModal.bind(this);
    this.toggleListing = this.toggleListing.bind(this);
    this.schwarzCheckBoxClick = this.schwarzCheckBoxClick.bind(this);
    this.findByKey = this.findByKey.bind(this);
    this.removeItemFromArray = this.removeItemFromArray.bind(this);

    this.listAllItems = this.listAllItems.bind(this);
    this.selectAllCheckBoxes = this.selectAllCheckBoxes.bind(this);
    this.loadSavedSettings = this.loadSavedSettings.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.handleBrokenImage = this.handleBrokenImage.bind(this);
    this.handleSupplierClick = this.handleSupplierClick.bind(this)
    this.handleSupplierWithScopeClick = this.handleSupplierWithScopeClick.bind(this)
    this.setEmailSettings = this.setEmailSettings.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
    // GDSN Subscription
    this.toggleGdsnSubscriptionModal = this.toggleGdsnSubscriptionModal.bind(this);
    this.specifyQuery = this.specifyQuery.bind(this);
    // Euvino Subscription
    this.toggleEuvinoSubscriptionModal = this.toggleEuvinoSubscriptionModal.bind(this);
    // Export Modal
    this.toggleExportModal = this.toggleExportModal.bind(this);
    this.PDFHandler = this.PDFHandler.bind(this);

    document.body.classList.add("white-content");
  }

  componentDidMount() {
    let source = process.env.REACT_APP_SOURCE
    if(!localStorage.getItem('currentSettings')) {
      let storage = {}
      storage[source] = {}
      localStorage.setItem('currentSettings', JSON.stringify(storage))
    } else if (!JSON.parse(localStorage.getItem('currentSettings'))[source]){
      let storage = JSON.parse(localStorage.getItem('currentSettings'))
      storage[source] = {}
      localStorage.setItem('currentSettings', JSON.stringify(storage))
    }
    if (source === 'migros') {
      this.setState({
        defaultTargetMarket: '756'
      })
    } else if (source === 'schwarz') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
        this.loadSavedSettings(source)
      } else {
        this.setState({
          defaultTargetMarket: '276'
        })
      }
    } else if (source === 'radeberger') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
        this.loadSavedSettings(source)
      } else {
        this.setState({
          defaultTargetMarket: '276'
        })
      }
      //TODO take out from condition when to be used for all clients
      // Make sure db script V4_add_user_roles.sql is executed first
      this.getUserRights();
    } else if (source === 'flaschenpost') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
        this.loadSavedSettings(source)
      } else {
        this.setState({
          defaultTargetMarket: '276'
        })
      }
      //TODO take out from condition when to be used for all clients
      // Make sure db script V4_add_user_roles.sql is executed first
      this.getUserRights();
    } else if (source === 'denner') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
        this.loadSavedSettings(source)
      } else {
        this.setState({
          defaultTargetMarket: '756'
        })
      }
    } else if (source === 'aldi') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
        this.loadSavedSettings(source)
      } else {
        this.setState({
          defaultTargetMarket: '276'
        })
      }
    } else if (source === 'bc') {
      if(Object.keys(JSON.parse(localStorage.getItem('currentSettings'))[source]).length > 0){
          this.loadSavedSettings(source)
      } else {
        this.setState({
        defaultTargetMarket: '276',
        exactSearch: true
      })
    }
    } else {
      this.setState({
        defaultTargetMarket: '276'
      });
    }    
    if(source === 'schwarz'){
      if (['mvp', 'a', 'q', 'lidl'].includes(process.env.REACT_APP_MODE)){    
        this.getGPCData();
      }
    }else{
      this.getGPCData();
    }
    this.getDataCount();
    this.getTargetMarkets();
    this.getDashboardData();    
  }

  footer() {
    let footer = []
    let p = []
    // p.push(<p key='footerp' style={{ marginRight: '2%', marginTop: '0.5%' }}><b onClick={() => this.toggleReleaseModal()} style={{ cursor: 'pointer' }}>1.0.0 Release Notes</b></p>)
    footer.push(<footer key='footer' style={footerStyle}>{p}</footer>)
    return footer
  }

  toggleReleaseModal() {
    this.setState({
      releaseModal: !this.state.releaseModal
    })
  }

  getDashboardData() {
    this.setState({ isDashboardLoadingComplete: false })
    this.context.apiService.getDashboardData()
      .then(res => res.json())
      .then(res => {
        this.setState({ dashboardData: res, isDashboardLoadingComplete: true })
      });
  }

  getGPCData(){
    this.context.apiService.getCompleteLangGPCData()
      .then(res => res.json())
      .then(res => {     
        this.setState({ completeGPCData: res })      
      });
    }


  clickExampleData(event) {
    let query = event.target.innerHTML.replace('„', '')
    query = query.replace('“', '')
    query = query.replace('<span class="MuiButtonlabel">', '')
    query = query.replace('<span class="MuiButton-label">', '')
    query = query.replace('</span>', '')
    if (this.state.toggleGPCModal) {
      query = query.split(' - ')[0]
    }
    this.setState({ inputValue: query, toggleGPCModal: false })
    this.searchKeyword(query);
  }

  getDataCount() {
    this.context.apiService.getDataCount()
      .then(res => res.json())
      .then(res => {
        let sources = res['sources'];
        this.setState({ sources: sources })

        let sourceSwitchState = []
        for (let i = 0; i < sources.length; i++) {
          let source = {}
          source[sources[i]['source']] = true
          sourceSwitchState.push(source)
        }
        
        if(JSON.parse(localStorage.getItem('currentSettings'))[process.env.REACT_APP_SOURCE]['sourceSwitches']){
          
          for(let i = 0; i < sourceSwitchState.length; i++){
            let storedSourceSwitches = JSON.parse(localStorage.getItem('currentSettings'))[process.env.REACT_APP_SOURCE]['sourceSwitches']
            if(sourceSwitchState[i][Object.keys(storedSourceSwitches[i])[0]]){
              sourceSwitchState[i][Object.keys(storedSourceSwitches[i])[0]] = Object.values(storedSourceSwitches[i])[0]
            }
          }
        }
        
        this.setState({ sourceSwitches: sourceSwitchState })
      });
  }

  generateSourceSwitches(t) {
    let sourceSwitches = []
    if (this.state.sourceSwitches.length > 0) {
      for (let i = 0; i < this.state.sourceSwitches.length; i++) {
        let sourceLabel = getDataSourceLabel(this.state.sources[i]['source'])
        sourceSwitches.push(<FormControlLabel key={'form ' + i} control={<Switch size="small" checked={this.state.sourceSwitches[i][this.state.sources[i]['source']]} value={this.state.sources[i]['source']} name={this.state.sources[i]['source']} color="primary" onChange={this.handleSourceSwitches} />} label={sourceLabel + ' (' + this.state.sources[i]['count'] + ' ' + t('searchResults.records') + ')'} labelPlacement='end' />, <br/>)
      }
    } else {
      sourceSwitches.push(<Label key='form label'>{t('searchResults.loadingDataSource')}</Label>)
    }
    return sourceSwitches
  }

  handleSourceSwitches(event) {
    if (this.state.sourceSwitches.length > 1) {
      let updateSourceSwitches = this.state.sourceSwitches
      for (let i = 0; i < updateSourceSwitches.length; i++) {
        if (event.target.value in updateSourceSwitches[i]) {
          updateSourceSwitches[i][event.target.value] = !updateSourceSwitches[i][event.target.value]
        }
      }
      this.setState({ sourceSwitches: updateSourceSwitches }, () => this.saveSettings(process.env.REACT_APP_SOURCE))
    }
  }

  getTargetMarkets() {
    let savedSettings
    if(JSON.parse(localStorage.getItem('currentSettings'))){
      savedSettings = JSON.parse(localStorage.getItem('currentSettings'))[process.env.REACT_APP_SOURCE]
    }
    if(savedSettings !== undefined && savedSettings['targetMarkets'] && savedSettings['targetMarketSwitches'] &&
      savedSettings['targetMarkets'].length > 0 && savedSettings['targetMarketSwitches'].length > 0
    ){
      this.loadSavedSettings(process.env.REACT_APP_SOURCE)
    } else {
      this.context.apiService.getTargetMarkets()
        .then(res => res.json())
        .then(res => {
          let targetMarketSwitchState = []
          let targetMarketSwitchesNew = []
          this.setState({ targetMarkets: res.aggregations.targetMarkets.buckets })
          for (let i = 0; i < res.aggregations.targetMarkets.buckets.length; i++) {
            let targetMarket = {}
            if (res.aggregations.targetMarkets.buckets[i]['key'] === this.state.defaultTargetMarket) {
              targetMarket[res.aggregations.targetMarkets.buckets[i]['key']] = true
            } else {
              targetMarket[res.aggregations.targetMarkets.buckets[i]['key']] = false
            }
            targetMarketSwitchState.push(targetMarket)
          }
          if (savedSettings['targetMarketSwitchesOld'] ===  undefined) {
            savedSettings['targetMarketSwitchesOld'] = targetMarketSwitchState
          } 
          targetMarketSwitchesNew = this.updateTargetMarketSwitches(savedSettings['targetMarketSwitchesOld'], targetMarketSwitchState)
          this.setState({ targetMarketSwitches: targetMarketSwitchesNew })
        });
    }
  }

  getUserRights() {
    this.context.apiService.getUserRights(localStorage.getItem('user'))
      .then(res => res.json())
      .then(res => {
        if ('listing' in res && res['listing'] === 'LISTING_READ_ONLY') {
          this.setState({ isUserListingReadOnly: true })
        }
        this.setState({ userRights: res })
      });
  }

  generateTargetMarketSwitches(t) {
    let targetMarketSwitches = [];
    const { targetMarketSwitches: switchesState, targetMarkets, mainSwitch } = this.state;
  
    const handleMainSwitchChange = () => {
      const newMainSwitchState = !this.state.mainSwitch;
      const updateTargetMarketSwitches = this.state.targetMarketSwitches.map(switchState => {
        const key = Object.keys(switchState)[0];
        return { [key]: newMainSwitchState };
      });
    
      this.setState(
        {
          mainSwitch: newMainSwitchState,
          targetMarketSwitches: updateTargetMarketSwitches
        },
        () => this.saveSettings(process.env.REACT_APP_SOURCE)
      );
    };

    const handleIndividualSwitchChange = (event) => {
      const { name, checked } = event.target;
  
      this.setState(prevState => {
        const newTargetMarketSwitches = prevState.targetMarketSwitches.map((switchState, index) => {
          const key = this.state.targetMarkets[index].key;
          return {
            [key]: key === name ? checked : switchState[key]
          };
        });
  
        const allSwitchesOn = newTargetMarketSwitches.every(switchState => Object.values(switchState)[0]);
  
        return { 
          targetMarketSwitches: newTargetMarketSwitches,
          mainSwitch: allSwitchesOn
        };
      }, () => {
        this.saveSettings(process.env.REACT_APP_SOURCE);
      });
    };

    if (switchesState.length > 0) {
      targetMarketSwitches.push(
        <FormControlLabel 
          key="main-switch"
          value="all"
          control={
            <Switch 
              size="small" 
              color="primary" 
              checked={mainSwitch} 
              onChange={handleMainSwitchChange} 
            />
          }
          label={t('searchResults.all')}
        />
      );
  
      for (let i = 0; i < targetMarkets.length; i++) {
        targetMarketSwitches.push(
          <FormControlLabel 
            key={'form-' + i} 
            control={
              <Switch 
                size="small" 
                checked={switchesState[i][targetMarkets[i].key]} 
                value={targetMarkets[i].key} 
                name={targetMarkets[i].key} 
                color="primary"
                onChange={handleIndividualSwitchChange} 
              />
            } 
            label={targetMarkets[i].countryCodeAlpha2} 
            labelPlacement="end" 
          />
        );
      }
    } else {
      targetMarketSwitches.push(<Label key='form-label'>{t('searchResults.loadingTargetMarkets')}</Label>);
    }
  
    return targetMarketSwitches;
  }

  handleGPCManufacturerFilterButtons(theFilterList) {
    let statesToUpdate = this.context.apiService.handleGPCManufacturerFilterButtons(theFilterList, this.state.originalRecordList, this.state.completeGPCData, this.state.language);
    this.setState(statesToUpdate);
    this.setState({languageChanged : false})
  }

  /**
   * In desperate need of refactoring
   */
  callSearchAPI(keyword, size, offset, logicalOperator, originSwitches, isBaseUnit, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter) {
        let source = process.env.REACT_APP_SOURCE
    if (source === 'migros') {
      this.migrosSearchAPI(keyword, size, offset, logicalOperator, originSwitches, isConsumerUnit, isBaseUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
    } else if (source === 'schwarz') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      if (['mvp', 'a', 'q', 'lidl'].includes(process.env.REACT_APP_MODE))
        this.schwarzMvpSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
      else
        this.schwarzSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
    } else if (source === 'radeberger') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.radebergerSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter)
    } else if (source === 'flaschenpost') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.flaschenpostSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter)
    } else if (source === 'denner') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.dennerSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
    } else if (source === 'aldi') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.aldiSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
    } else if (source === 'bc') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.bcSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, childTradeItemRadio, searchQueryFilter)
    } else if (source === 'markant-book') {
      let descriptorCode = '*'
      if (isBaseUnit) {
        descriptorCode = 'BASE_UNIT_OR_EACH'
      }
      this.setState({ selectAllItems: false })
      this.markantSearchAPI(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
    }
  }

  migrosSearchAPI(keyword, size, offset, logicalOperator, originSwitches, isConsumerUnit, isBaseUnit, exactSearch, targetMarketSwitches, searchQueryFilter) {
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, originSwitches, isConsumerUnit, isBaseUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let manufacturer = ''
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              
              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)

              if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"]
              } else if (typeof res.hits.hits[i]['_source']["manufacturer"] !== 'undefined' && res.hits.hits[i]['_source']["manufacturer"] !== '') {
                manufacturer = res.hits.hits[i]['_source']["manufacturer"]
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }
              if ('gpcCategoryCode' in res.hits.hits[i]['_source']) {
                if (!(gpcFilter.includes(res.hits.hits[i]['_source']["gpcCategoryCode"] + ' - ' + res.hits.hits[i]['_source']['gpcCategoryDesc']))) {
                  gpcFilter.push(res.hits.hits[i]['_source']["gpcCategoryCode"] + ' - ' + res.hits.hits[i]['_source']['gpcCategoryDesc'])
                }
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter,  languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          } 

          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  schwarzSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches) {

    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let manufacturer = ''
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              
              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)
              
              if (res.hits.hits[i]['_source']["Manufacturer"] !== null) {
                if (res.hits.hits[i]['_source']["Manufacturer"].partyName === '' && 'informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"]
                } else if (res.hits.hits[i]['_source']["Manufacturer"].partyName !== '') {
                  manufacturer = res.hits.hits[i]['_source']["Manufacturer"].partyName
                }
                if (!(manufacturerFilter.includes(manufacturer))) {
                  manufacturerFilter.push(manufacturer)
                }
              }
              if (!(gpcFilter.includes(res.hits.hits[i]['_source']["gpcCategoryCode"] + ' - ' + res.hits.hits[i]['_source']['gpcBrickDescriptionDe']))) {
                gpcFilter.push(res.hits.hits[i]['_source']["gpcCategoryCode"] + ' - ' + res.hits.hits[i]['_source']['gpcBrickDescriptionDe'])
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          } 
          this.setState({ records: responseRecords, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  schwarzMvpSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches) {
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let gpcBrickDescription = ''
          let gpcBrickCode = ''
          let idList = []
          let listingDict = {}
          let listingIndexes = []          
          
          if ( !res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''
              let source = res.hits.hits[i]['_source']

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)
              
              idList.push(res.hits.hits[i]['_id'])

              listingDict[res.hits.hits[i]['_id']] = source['listing']
              listingIndexes[i] = source['listing']

              if ('manufacturerOfTradeItem' in source && 'partyName' in source['manufacturerOfTradeItem'][0]) {
                  manufacturer = source["manufacturerOfTradeItem"][0].partyName
              } else if ('informationProviderOfTradeItem' in source && 'partyName' in source['informationProviderOfTradeItem']) {
                  manufacturer = source["informationProviderOfTradeItem"].partyName
              }

              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }
              gpcBrickCode = getBrickCode(source)              
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
              if (!(gpcFilter.includes(gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push(gpcBrickCode + ' - ' + gpcBrickDescription)
              }
              gpcBrickDescription = ''
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords, listedDict: listingDict, listedIndexes: listingIndexes })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          }

          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideCINLinearProgress: true,
            disableCINMenuItem: false,
            hideKArticlePassLinearProgress: true,
            disableKArticlePassMenuItem: false,
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))
          this.setState({ records: newResponseRecords, idList: idList, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }  

  radebergerSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter) {
    
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let gpcBrickCode = ''
          let gpcBrickDescription = ''
          let listingDict = {}
          let idDict = {}
          let idList = []
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)
              
              idDict[res.hits.hits[i]['_source']['gtin']] = res.hits.hits[i]['_id']
              idList.push(res.hits.hits[i]['_id'])
              listingDict[res.hits.hits[i]['_id']] = res.hits.hits[i]['_source']['listing']
              if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                  manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                }
              } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                }
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }
              gpcBrickCode = getBrickCode(res.hits.hits[i]['_source'])
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)

              if (!(gpcFilter.includes( gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push( gpcBrickCode + ' - ' + gpcBrickDescription)
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords, listedDict: listingDict, idDict: idDict })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          } 

          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, idList: idList, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  flaschenpostSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter) {
    
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, listedFilter, childTradeItemRadio, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let gpcBrickCode = ''
          let gpcBrickDescription = ''
          let listingDict = {}
          let idDict = {}
          let idList = []
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)
              
              idDict[res.hits.hits[i]['_source']['gtin']] = res.hits.hits[i]['_id']
              idList.push(res.hits.hits[i]['_id'])
              listingDict[res.hits.hits[i]['_id']] = res.hits.hits[i]['_source']['listing']
              if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                  manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                }
              } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                }
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }
              gpcBrickCode = getBrickCode(res.hits.hits[i]['_source'])
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)

              if (!(gpcFilter.includes( gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push( gpcBrickCode + ' - ' + gpcBrickDescription)
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords, listedDict: listingDict, idDict: idDict })
          }
          
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          } 

          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, idList: idList, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  bcSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, childTradeItemRadio, searchQueryFilter)
  {
    
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      // TODO fix loader in case of error from API server
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, childTradeItemRadio, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let gpcBrickCode = ''
          let gpcBrickDescription = ''
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)

              if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                  manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                }
              } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                }
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }              
              gpcBrickCode = getBrickCode(res.hits.hits[i]['_source'])
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
              if (!(gpcFilter.includes( gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push(gpcBrickCode + ' - ' + gpcBrickDescription)
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          }
          
          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  dennerSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter) {
    
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let gpcBrickCode = ''
          let gpcBrickDescription = ''
          let manufacturerFilter = []
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)

              if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                  manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                }
              } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                }
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }              
              gpcBrickCode = getBrickCode(res.hits.hits[i]['_source'])
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
              if (!(gpcFilter.includes(gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push(gpcBrickCode + ' - ' + gpcBrickDescription)
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, languageChanged : false,  manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          }
          
          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  markantSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches) {
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches)
          .then(res => res.json())
          .then(res => {
            let responseRecords = []
            let gpcFilter = []
            let manufacturerFilter = []
            if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
              this.setState({ isTextboxEmpty: true, noRecordsFound: true })
            } else {
              for (let i = 0; i < res.hits.hits.length; i++) {
                let manufacturer = ''

                const product = res.hits.hits[i]._source
                product['productId'] = res.hits.hits[i]._id
                responseRecords.push(product)

                if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                  if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                    manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                  }
                } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                  if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                    manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                  }
                }
                if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                  manufacturerFilter.push(manufacturer)
                }
                if (!(gpcFilter.includes(res.hits.hits[i]['_source']["gpcBrickCode"] + ' - ' + res.hits.hits[i]['_source']['gpcBrickDescriptionDe']))) {
                  gpcFilter.push(res.hits.hits[i]['_source']["gpcBrickCode"] + ' - ' + res.hits.hits[i]['_source']['gpcBrickDescriptionDe'])
                }
              }
              this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
            }
            let total_hits = 0;
            if (res.hasOwnProperty('hits')) {
              total_hits = res.hits.total.value
            } 
            this.setState({ records: responseRecords, total: total_hits, showLoader: false })
          });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }

  aldiSearchAPI(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter) {
    
    if (keyword.length !== 0) {
      this.setState({ isTextboxEmpty: false, showLoader: true })
      this.context.apiService.search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, searchQueryFilter)
        .then(res => res.json())
        .then(res => {
          let responseRecords = []
          let gpcFilter = []
          let manufacturerFilter = []
          let gpcBrickCode = ''
          let gpcBrickDescription = ''
          if (!res.hasOwnProperty("hits") || res.hits.hits.length === 0) {
            this.setState({ isTextboxEmpty: true, noRecordsFound: true })
          } else {
            for (let i = 0; i < res.hits.hits.length; i++) {
              let manufacturer = ''

              const product = res.hits.hits[i]._source
              product['productId'] = res.hits.hits[i]._id
              responseRecords.push(product)

              if ('manufacturerOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['manufacturerOfTradeItem'][0]) {
                  manufacturer = res.hits.hits[i]['_source']["manufacturerOfTradeItem"][0].partyName
                }
              } else if ('informationProviderOfTradeItem' in res.hits.hits[i]['_source']) {
                if ('partyName' in res.hits.hits[i]['_source']['informationProviderOfTradeItem']) {
                  manufacturer = res.hits.hits[i]['_source']["informationProviderOfTradeItem"].partyName
                }
              }
              if (!(manufacturerFilter.includes(manufacturer)) && manufacturer !== '') {
                manufacturerFilter.push(manufacturer)
              }              
              gpcBrickCode = getBrickCode(res.hits.hits[i]['_source'])
              gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
              if (!(gpcFilter.includes( gpcBrickCode + ' - ' + gpcBrickDescription))) {
                gpcFilter.push(gpcBrickCode + ' - ' + gpcBrickDescription)
              }
            }
            this.setState({ noRecordsFound: false, gpcFilterList: gpcFilter,  languageChanged : false, manufacturerFilterList: manufacturerFilter, originalRecordList: responseRecords })
          }
          let total_hits = 0;
          if (res.hasOwnProperty('hits')) {
            total_hits = res.hits.total.value
          } 

          const newResponseRecords = responseRecords.map(record => ({
            ...record, 
            hideInformaticaXMLLinearProgress: true,
            disableInformaticaXMLMenuItem: false,
            hideMessageDownloadLinearProgress: true,
            disableMessageDownloadMenuItem: false,
          }))

          this.setState({ records: newResponseRecords, total: total_hits, showLoader: false })
        });
    }
    else {
      this.setState({ records: null, total: 0, isTextboxEmpty: true })
    }
  }   

  handleSearch(e) {
    this.setState({
      offset: 0,
      pageNumber: 1
    }, () => {
      this.saveSettings(process.env.REACT_APP_SOURCE)
      this.callSearchAPI(this.state.keyword, this.state.size, this.state.offset, this.state.logicalOperator, this.state.sourceSwitches, this.state.isBaseUnit, this.state.isConsumerUnit, this.state.exactSearch, this.state.targetMarketSwitches, this.state.listedFilter, this.state.childTradeItemRadio, this.state.searchQueryFilter)
    });
  }

  onSearchTextChange(e) {
    this.setState({ inputValue: e.target.value, keyword: e.target.value})
    const query = e.target.value
    this.searchKeyword(query);
  }

  searchKeyword(query) {
    if (query[0] === '~') {
      query = ''
    }
    query = query.replace(/[{()}!"^+*\\/\\[\]]/g, '')
    this.setState({
      keyword: query,
      offset: 0,
      pageNumber: 1,
      noRecordsFound: false
    });
  }

  handleSizeChange(event) {
    this.setState({
      size: event.target.value,
      offset: 0,
      pageNumber: 1
    }, () => this.saveSettings(process.env.REACT_APP_SOURCE));
  }

  handleLogicalOperatorRadioChange(event) {
    this.setState({
      logicalOperator: event.target.value,
      offset: 0,
      pageNumber: 1
    }, () => this.saveSettings(process.env.REACT_APP_SOURCE));
  }

  handleChildTradeItemRadioChange(event) {
    this.setState({
      childTradeItemRadio: event.target.value,
      offset: 0,
      pageNumber: 1
    }, () => this.saveSettings(process.env.REACT_APP_SOURCE));
  }

  handlePageClick(offset, pageNumber) {
    this.setState({pageNumber: pageNumber})
    this.setState({ offset: offset });
    this.callSearchAPI(this.state.keyword, this.state.size, offset, this.state.logicalOperator, this.state.sourceSwitches, this.state.isBaseUnit, this.state.isConsumerUnit, this.state.exactSearch, this.state.targetMarketSwitches, this.state.listedFilter, this.state.childTradeItemRadio, this.state.searchQueryFilter)
  }

  handleExactSearchChange(event) {
    this.setState({ exactSearch: !this.state.exactSearch },
      () => { this.saveSettings(process.env.REACT_APP_SOURCE)} );
  }

  handleListedFilterChange(event) {
    this.setState({ listedFilter: !this.state.listedFilter }, () => { this.saveSettings(process.env.REACT_APP_SOURCE)})
  }

  handleOnlyBaseUnitChange(event) {
    this.setState({ isBaseUnit: !this.state.isBaseUnit }, () => this.saveSettings(process.env.REACT_APP_SOURCE))
  }

  handleOnlyConsumerUnitChange(event) {
    this.setState({ isConsumerUnit: !this.state.isConsumerUnit }, () => this.saveSettings(process.env.REACT_APP_SOURCE))
  }

  handleSupplierClick(gln) {
    this.setState({ isBaseUnit: true }, () => this.saveSettings(process.env.REACT_APP_SOURCE))
    this.callSearchAPI(gln, this.state.size, this.state.offset, this.state.logicalOperator, this.state.sourceSwitches, !this.state.isBaseUnit, this.state.isConsumerUnit, this.state.exactSearch, this.state.targetMarketSwitches, this.state.listedFilter, this.state.childTradeItemRadio, this.state.searchQueryFilter)
    this.setState({ inputValue: gln })
    this.searchKeyword(gln);
  }

  handleSupplierWithScopeClick(gln) {
    this.setState({ isBaseUnit: false }, () => this.saveSettings(process.env.REACT_APP_SOURCE))
    this.callSearchAPI(gln, this.state.size, this.state.offset, this.state.logicalOperator, this.state.sourceSwitches, !this.state.isBaseUnit, this.state.isConsumerUnit, this.state.exactSearch, this.state.targetMarketSwitches, this.state.listedFilter, this.state.childTradeItemRadio, this.state.searchQueryFilter)
    this.setState({ inputValue: gln })
    this.searchKeyword(gln);
  }

  renderTable(t) {
    let source = process.env.REACT_APP_SOURCE
    if (source === 'migros') {
      return this.context.viewRenderer.renderTable(this.state.records, this.generateMigrosXMLContent, this.toggleGPCModalSearch, this.handleSnackBarOpen, this.downloadOriginalMessage);
    } else if (source === 'schwarz') {
      if (['mvp', 'a', 'q', 'lidl'].includes(process.env.REACT_APP_MODE)){
        return this.context.viewRenderer.renderTable(this.state.records, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch, this.schwarzCheckBoxClick, this.state.listedIndexes,this.state.completeGPCData,this.state.language,t, this.downloadCIN, this.downloadKauflandArticlepassMoreButton, this.handleSnackBarOpen, this.downloadOriginalMessage);
      }
      else{
        return this.context.viewRenderer.renderTable(this.state.records, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch);
      }
    } else if (source === 'radeberger') {
      return this.context.viewRenderer.renderTable(this.state.records, this.state.idList, this.state.listedDict, this.generateOtherOriginsXMLContent, this.generateEuvinoContent, this.toggleGPCModalSearch, this.toggleListing, this.state.isUserListingReadOnly, this.state.completeGPCData, this.state.language, this.handleSnackBarOpen, this.downloadOriginalMessage);
    } else if (source === 'flaschenpost') {
      return this.context.viewRenderer.renderTable(this.state.records, this.state.idList, this.state.listedDict, this.generateOtherOriginsXMLContent, this.generateEuvinoContent, this.toggleGPCModalSearch, this.toggleListing, this.state.isUserListingReadOnly, this.state.completeGPCData, this.state.language, this.handleSnackBarOpen, this.downloadOriginalMessage);
    } else if (source === 'bc') {
      return this.context.viewRenderer.renderTable(this.state.records, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch, this.toggleTradeItemHierarchyModal, this.onTradeItemHierarchyProduct ,this.generateEuvinoContent, this.state.completeGPCData, this.state.language, this.handleSnackBarOpen, this.downloadOriginalMessage);
    } else if (source === 'denner') {
      return this.context.viewRenderer.renderTable(this.state.records, this.state.listedDict, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch, this.state.completeGPCData, this.state.language);
    } else if (source === 'aldi') {
      return this.context.viewRenderer.renderTable(this.state.records, this.state.listedDict, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch, this.state.completeGPCData, this.state.language, this.downloadInformaticaXML, this.handleSnackBarOpen, this.downloadOriginalMessage);
    } else if (source === 'markant-book') {
      return this.context.viewRenderer.renderTable(this.state.records, this.state.listedDict, this.generateOtherOriginsXMLContent, this.toggleGPCModalSearch, this.state.language, this.handleSnackBarOpen);
    }
  }

  toggleGPCModalSearch(idx) {
    let source = process.env.REACT_APP_SOURCE
    let mode = process.env.REACT_APP_MODE
    this.setState({
      toggleGPCModal: !this.state.toggleGPCModal
    })
    if (!this.state.toggleGPCModal) {
      let gpcSegmentDesc = ''
      let gpcFamilyDesc = ''
      let gpcClassDesc =  ''
      let gpcBrickDesc = ''
      let gpcBrickCode = ''
      let gpcSegmentCode = this.state.records[idx]['gpcSegmentCode']
      let gpcFamilyCode = this.state.records[idx]['gpcFamilyCode']
      let gpcClassCode =  this.state.records[idx]['gpcClassCode']
      if('gdsnTradeItemClassification' in this.state.records[idx] &&  'gpcCategoryCode' in this.state.records[idx].gdsnTradeItemClassification){
        gpcBrickCode = this.state.records[idx].gdsnTradeItemClassification.gpcCategoryCode
      }

      if(source !== 'migros'){
        if(source === 'schwarz'){
          if ( ['mvp', 'a', 'q', 'lidl'].includes(mode)){
            if(this.state.completeGPCData[this.state.language] !== undefined){
              if(this.state.completeGPCData[this.state.language][gpcBrickCode] !== undefined){
                gpcSegmentDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['segment_desc']
                gpcFamilyDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['family_desc']
                gpcClassDesc =  this.state.completeGPCData[this.state.language][gpcBrickCode]['class_desc']
                gpcBrickDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['brick_desc'] 
                gpcSegmentCode = this.state.completeGPCData[this.state.language][gpcBrickCode]['segment_code']
                gpcFamilyCode = this.state.completeGPCData[this.state.language][gpcBrickCode]['family_code']
                gpcClassCode =  this.state.completeGPCData[this.state.language][gpcBrickCode]['class_code']
              }  
            }else 
            {
              if(this.state.completeGPCData[defaultLanguage][gpcBrickCode] !== undefined){
                gpcSegmentDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['segment_desc']
                gpcFamilyDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['family_desc']
                gpcClassDesc =  this.state.completeGPCData[defaultLanguage][gpcBrickCode]['class_desc']
                gpcBrickDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['brick_desc'] 
                gpcSegmentCode = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['segment_code']
                gpcFamilyCode = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['family_code']
                gpcClassCode =  this.state.completeGPCData[defaultLanguage][gpcBrickCode]['class_code']
              }
            }
          }else{ //For old Schwarz-lidl environment
            if(this.state.language === 'de'){
              gpcSegmentDesc = this.state.records[idx]['gpcSegmentDescriptionDe']
              gpcFamilyDesc = this.state.records[idx]['gpcFamilyDescriptionDe']
              gpcClassDesc =  this.state.records[idx]['gpcClassDescriptionDe']
              gpcBrickDesc = this.state.records[idx]['gpcBrickDescriptionDe']
            }else{
              gpcSegmentDesc = this.state.records[idx]['gpcSegmentDescription']
              gpcFamilyDesc = this.state.records[idx]['gpcFamilyDescription']
              gpcClassDesc =  this.state.records[idx]['gpcClassDescription']
              gpcBrickDesc = this.state.records[idx]['gpcBrickDescription']
            }         
          }            
        }else{ //for non-schwarz and non-Migros
          if(this.state.completeGPCData[this.state.language] !== undefined){
            if(this.state.completeGPCData[this.state.language][gpcBrickCode] !== undefined){
              gpcSegmentDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['segment_desc']
              gpcFamilyDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['family_desc']
              gpcClassDesc =  this.state.completeGPCData[this.state.language][gpcBrickCode]['class_desc']
              gpcBrickDesc = this.state.completeGPCData[this.state.language][gpcBrickCode]['brick_desc']
              gpcSegmentCode = this.state.completeGPCData[this.state.language][gpcBrickCode]['segment_code']
              gpcFamilyCode = this.state.completeGPCData[this.state.language][gpcBrickCode]['family_code']
              gpcClassCode =  this.state.completeGPCData[this.state.language][gpcBrickCode]['class_code']
            }
          }else
          {
            if(this.state.completeGPCData[defaultLanguage][gpcBrickCode] !== undefined){              
              gpcSegmentDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['segment_desc']
              gpcFamilyDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['family_desc']
              gpcClassDesc =  this.state.completeGPCData[defaultLanguage][gpcBrickCode]['class_desc']
              gpcBrickDesc = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['brick_desc']
              gpcSegmentCode = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['segment_code']
              gpcFamilyCode = this.state.completeGPCData[defaultLanguage][gpcBrickCode]['family_code']
              gpcClassCode =  this.state.completeGPCData[defaultLanguage][gpcBrickCode]['class_code']
            }
          }
        }
      }else{ //For Migros
        gpcSegmentDesc = this.state.records[idx]['gpcSegmentDescriptionEn']
        gpcFamilyDesc = this.state.records[idx]['gpcFamilyDescriptionEn']
        gpcClassDesc =  this.state.records[idx]['gpcClassDescriptionEn']            
        gpcBrickDesc = this.state.records[idx]['gpcCategoryDesc']            
      }          
      this.setState({ segment: gpcSegmentCode + ' - ' + gpcSegmentDesc, family: gpcFamilyCode + ' - ' + gpcFamilyDesc, class: gpcClassCode + ' - ' + gpcClassDesc, brick: gpcBrickCode + ' - ' + gpcBrickDesc });
    }
  }  

  toggleChangePassword() {
    this.setState({
      toggleChangePasswordModal: !this.state.toggleChangePasswordModal
    })
  }

  logout() {
    fetch('/products/logout/', {
      method: 'get',
      headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
      }
    }).then(res => {
      
    }).finally(res => {
      localStorage.removeItem('user');
      localStorage.removeItem('saved');
      this.props.history.push('/login')
    })
  }

  detectEnterKey(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  toggleImageModal() {
    this.setState({
      imageModal: !this.state.imageModal,
      tableModal: false,
      cicModal: false,
      tradeItemRecordsModal: false,
      tradeItemHierarchyModal: false,
      tradeItemWithdrawalModal: false,
      documentModal: false
    })
  }

  toggleDocumentModal() {
    this.setState({
      documentModal: !this.state.documentModal,
      tableModal: false,
      cicModal: false,
      tradeItemRecordsModal: false,
      tradeItemHierarchyModal: false,
      tradeItemWithdrawalModal: false,
      imageModal: false
    })
  }

  toggleTableModal() {
    this.setState({
      tableModal: !this.state.tableModal,
      imageModal: false,
      cicModal: false,
      tradeItemRecordsModal: false,
      tradeItemHierarchyModal: false,
      tradeItemWithdrawalModal: false,
      documentModal: false
    })
  }

  toggleCicModal() {
    this.setState({
      cicModal: !this.state.cicModal,
      tableModal: false,
      imageModal: false,
      tradeItemRecordsModal: false,
      tradeItemHierarchyModal: false,
      tradeItemWithdrawalModal: false,
      documentModal: false
    })
  }

  toggleTradeItemRecordsModal() {
    this.setState({
      cicModal: false,
      tableModal: false,
      imageModal: false,
      tradeItemRecordsModal: !this.state.tradeItemRecordsModal,
      tradeItemHierarchyModal: false,
      tradeItemWithdrawalModal: false,
      documentModal: false
    })
  }

  toggleTradeItemHierarchyModal() {
    this.setState({
      isTradeItemHierarchyModalOpen: !this.state.isTradeItemHierarchyModalOpen
    })
  }

  onTradeItemHierarchyProduct(product) {
    this.setState({
      tradeItemHierarchyProduct: product
    })
  }

  toggleTradeItemWithdrawalModal() {
    this.setState({
      cicModal: false,
      tableModal: false,
      imageModal: false,
      tradeItemRecordsModal: false,
      isTradeItemHierarchyModalOpen: false,
      tradeItemWithdrawalModal: !this.state.tradeItemWithdrawalModal,
      documentModal: false
    })
  }

  setEmailSettings(t){
    const eMailIdx = this.state.index
    const eMailItemDetails = this.state.records[eMailIdx]
    const eMailLanguage = this.state.language

    sendEmail(eMailItemDetails, eMailLanguage, t)
   }

  /* To get product details from Elastic search database */
  getProductDetailsFromEs(productId, origin, contentLanguage, labelLanguage) {
    this.context.apiService.getProductDetailsFromEs(productId, origin, contentLanguage, labelLanguage)
    .then(res => res.json())
    .then(res => {
      let customAttributes = {}
      if(origin.toLowerCase() === "sap") {
        try {
          let gtinList = []
          let gtinMain = ''
          gtinList = res["customAttributes"]["listingInformation"][0]["gtinlist"]
          gtinList = gtinList.filter(gtin => gtin.gtinMain === "true")
          gtinMain = gtinList[0]["gtin"]
          res["articleInformation"]["gtin"] = gtinMain
        } catch {
          console.log('Record having insufficient or incorrect listingInformation information.')
        }
      }
      if (res['customAttributes'] !== undefined) {
        customAttributes = res['customAttributes']
        delete res['customAttributes']
        this.setState({ customAttributes: customAttributes })
      } else {
        this.setState({ customAttributes: customAttributes })
      }
      this.setState({ productDetails: res })
    });
    this.setState({ dataSource: origin })
    this.setState({ productId: productId })
    this.setState({ productDetailsLanguage: contentLanguage})
  }

  // @TODO Remove below function after decommissioning the Schwarz PROD and Migros platform
  generateProductDetails(xml, origin) {
    this.context.apiService.getProductDetails(xml, origin)
      .then(res => res.json())
      .then(res => {
        this.setState({ productDetails: res })
      });

    this.setState({ dataSource: origin })
  }

  traverseProductDetails(product, t) {
    let tableContent = []
    const prefix = 'productDetailsModal'
    Object.keys(product).map(function (key) {
      if (typeof product[key] !== 'string') {
        
        tableContent.push(<tr key={'row' + key}><td key={'data' + key} style={{color: palette.error.main, fontWeight: 'bold', fontSize: 'large', width: '35%' }}>
          {translateKey(key, prefix)}</td><td key={'empty' + key}></td>
        </tr>)
        tableContent.push(this.traverseProductDetails(product[key], t))
      } else {
        
        let label_value = ''
        if (['website', 'recipe_website', 'product_website'].includes(key))  {
          let url = product[key];
          if (url && !url.startsWith('http')) {
            url = 'https://' + url;
          }
          label_value = (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {translateKey('linkToWebsite', prefix)}
            </a>
          );
        } else {
          label_value = product[key]
        }

        tableContent.push(<tr key={'row' + key}><td key={'key' + key}>{translateKey(key, prefix)}</td><td key={'data' + key}>{label_value}</td></tr>)
      }
    }.bind(this));
    return tableContent
  }
  
  generateModal(t) {
    let source = process.env.REACT_APP_SOURCE
    let content = null
    if (source === 'migros') {
      content = this.generateMigrosModal(t)
    } else {
      content = this.generateOtherOriginsModal(t)
    }
    return content
  }

  PDFHandler(url, recordId){
    document.getElementById("circleLoading"+recordId).style.display = 'block'

    this.context.apiService.downloadProductPdf(url)
    .then((response) => {
        if (response.status === 200) {
          return response.blob()
        } else {
          console.error('Error:', response)
          return false
        }
    })
    .then((blob) => {
      if ( blob ) {
        let blobObject = new Blob([blob], {type: 'application/pdf'});
        let blobURL = URL.createObjectURL(blobObject)
        openInNewTab(blobURL)
      } else {
        document.getElementById("errorText"+recordId).style.display = 'block'
        document.getElementById("errorText"+recordId).innerHTML = 'Error in loading'
      }
    })
    .finally(() => {
      document.getElementById("circleLoading"+recordId).style.display = 'none'
    });
  }

  handleBrokenImage(e){
    e.preventDefault()
    e.target.src=require('../assets/img/Broken_or_inaccessible_image_link-128.png')
  }

  generateMigrosModal(t) {
    let content = []
    let divContent = []
    let gridContent = []
    let columnContent = []
    let product = {}
    let tableContent = []
    let containsImage = false
    let imageDiv = []
    let rowDiv = []
    if (this.state.toggleXMLModal) {
      if (this.state.tableModal) {
        if (Object.keys(this.state.productDetails).length > 0) {
          product = this.state.productDetails
          content.push(<h3 key='h3'>{product['articleInformation']['gtin'] + ' / ' + product['articleInformation']['descriptionShort']}</h3>)
          content.push(<h4 key='h5' style={{ width: '130vh' }}>{product['articleInformation']['tradeItemDescription']}</h4>)

          tableContent = this.traverseProductDetails(product, t)
          columnContent.push(<div key='tablediv' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '75vh', width: '130vh' }}><Table key='table' borderless striped><tbody key='body'>{tableContent}</tbody></Table></div>)
          if (this.state.images) {
            let distinctImages = [...new Set(this.state.images)]
            for (let i = 0; i < distinctImages.length; i++) {
              if (this.state.images[i]) {
                imageDiv.push(<div key={'divimage' + i} style={{ marginLeft: '10%', marginTop: '2%', width: '200px', height: '200px' }}><img key={'image '} alt="urlimage" style={{ maxWidth: '100%', maxHeight: '100%' }} src={distinctImages[i]} /></div>)
                containsImage = true
              }
            }
          }
          if (!containsImage) {
            columnContent.push(<h4 key='noimagesfound' style={{ marginLeft: '5%' }}>Kein Bild verfügbar</h4>)
          } else {
            columnContent.push(<div key='imageDiv' style={{ display: 'flex', flexDirection: 'column', marginLeft: '10%' }}>{imageDiv}</div>)
          }
          rowDiv.push(<div key='flexcolumn' style={{ display: 'flex', flexDirection: 'row' }}>{columnContent}</div>)
          content.push(<div key='flexcolumn' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '75vh' }}>{rowDiv}</div>)
        }
      } else if (!this.state.imageModal && !this.state.tableModal) {
        content.push(<h2 key='1'>Inhalt</h2>)
        if (this.state.toggleXMLModal && !this.state.treeView) {
          divContent.push(this.state.treeList)
        } else if (this.state.toggleXMLModal && this.state.treeView) {
          divContent.push(<XMLViewer key='5' xml={this.state.xmlContent} theme={customTheme} />)
        }
        content.push(<div key='2' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '80vh' }}>{divContent}</div>)
      }
      else if (this.state.imageModal) {
        content.push(<h2 key='3'>{t('productDetailsModal.images')}</h2>)
        if (this.state.images) {
          for (let i = 0; i < this.state.images.length; i++) {
            let columnContent = []
            columnContent.push(<div key={'div' + i} style={{ margin: 'auto', width: '300px', height: '300px' }}><img key={'image ' + i} alt="urlimage" style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100%', marginRight: '3%' }} src={this.state.images[i]} onClick={() => window.open(this.state.images[i], "_blank")} /></div>)
            if (this.state.imageTypes[i]) {
              columnContent.push(<Label key={'label' + i} style={{ marginTop: '3%' }}>{'Typ: ' + this.state.imageTypes[i]}</Label>)
            }
            if (this.state.imageNames[i]) {
              columnContent.push(<Label key={'labelname' + i}>{'File name: ' + this.state.imageNames[i]}</Label>)
            }
            if (!this.state.imageTypes[i] && !this.state.imageNames[i]) {
              columnContent.push(<Label key={'label2' + i}>Keine Information verfügbar.</Label>)
            }
            divContent.push(<div key={'column' + i} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '10%', marginTop: '5%' }}>{columnContent}</div>)
          }
          gridContent.push(<div key={'grid'} style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>{divContent}</div>)
        }
        content.push(<div key='4' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '70vh', marginTop: '5%' }}>{gridContent}</div>)
      }
    }
    return content
  }

  filterWebsiteURL(urls_list){
    if (urls_list) {
      let websiteURLs = []
      websiteURLs = urls_list.filter(url => 
        url.referencedFileTypeCode === 'WEBSITE' ||
        url.referencedFileTypeCode === 'RECIPE_WEBSITE' ||
        url.referencedFileTypeCode === 'PRODUCT_WEBSITE'
        )

      return (websiteURLs.length > 0) ? websiteURLs : null
    } else {
      return null
    }
  }

  generateStandardProductModal(t) {
    let content = null
    let product = {}

    product = this.state.productDetails
  
    if (Object.keys(this.state.productDetails).length > 0) {
      product = this.state.productDetails
      content = (
        <ProductModal
          t = {t}
          product={ product }
          customAttributes = { this.state.customAttributes }
        />
      )
    }  
    return content
  }
  
  generateOtherOriginsModal(t) {
    let content = []
    let divContent = []
    let gridContent = []
    let columnContent = []
    let product = {}
    let tableContent = []
    let customAttributesTableContent = []
    let containsImage = false
    let imageDiv = []
    let rowDiv = []
    let customAttributes = []
    let contentDiv = []
        
    if (this.state.toggleXMLModal) {
      if (this.state.tableModal) {
        
        if (Object.keys(this.state.productDetails).length > 0) {

          product = this.state.productDetails
          customAttributes = this.state.customAttributes

          let websiteUrls = this.filterWebsiteURL(this.state.imgList)
          if (websiteUrls !== null) {
            if (!('moreItemInformation' in product)) {
                product['moreItemInformation'] = {}
            }
            for (const item of websiteUrls) {
              if (item.referencedFileTypeCode === 'WEBSITE') {
                  product['moreItemInformation']['website'] = item.uniformResourceIdentifier;
              } else if (item.referencedFileTypeCode === 'PRODUCT_WEBSITE') {
                  product['moreItemInformation']['product_website'] = item.uniformResourceIdentifier;
              } else if (item.referencedFileTypeCode === 'RECIPE_WEBSITE') {
                  product['moreItemInformation']['recipe_website'] = item.uniformResourceIdentifier;
              }
            }
          }

          let header_text = product['articleInformation']['gtin']
          if ( 'descriptionShort' in product['articleInformation'] ) {
            header_text = `${header_text} / ${product['articleInformation']['descriptionShort']}`
          }
          
          content.push(<h3 key='h3' style={{color: palette.text.main}}>{header_text}</h3>)
          content.push(<h4 key='h5' style={{color: palette.text.main}}>{product['articleInformation']['tradeItemDescription']}</h4>)
          tableContent = this.generateStandardProductModal(t)
        }
        contentDiv.push(<Table key='table' borderless striped><tbody key='body'>{tableContent}</tbody></Table>)
        if(customAttributesTableContent.length > 0) {
          contentDiv.push(<div key='tablediv'><h4 key='cah4' style={{ fontWeight: 'bold', fontSize: 'large', width: '35%', color: palette.text.main, paddingLeft: '0.4rem' }}>{t('productDetailsModal.customAttributesLabel')}</h4><Table key='customTable' borderless striped><tbody key='customBody'>{customAttributesTableContent}</tbody></Table></div>)
        }
        columnContent.push(<div key='tablediv' style={{ display: 'block', overflow: 'auto', position: 'relative', width: '130vh' }}>{contentDiv}</div>)

        if (this.state.imgList) {
          for (let i = 0; i < this.state.imgList.length; i++) {
            if (this.state.imgList[i]['referencedFileTypeCode'] === 'PRODUCT_IMAGE') {
              let imageUrl = this.state.imgList[i]['uniformResourceIdentifier']
              if(['tif', 'tiff'].includes(imageUrl.split('.').slice(-1)[0])){
                imageDiv.push(<div key={'divimage' + i} style={{ marginLeft: '10%', marginTop: '2%', width: '200px', height: '200px' }}>
                  <a href={imageUrl}>
                    <img key={'imageNoPreview'} alt="urlimage" style={{ maxWidth: '100%', maxHeight: '100%' }} src={require('../assets/img/No_Preview_image.png')}/>
                  </a>
                  </div>)
                  containsImage = true
              } else {
                imageDiv.push(<div key={'divimage' + i} style={{ marginLeft: '10%', marginTop: '2%', width: '200px', height: '200px' }}><img key={'image '} alt="urlimage" onError={ (e) => {this.handleBrokenImage(e)}} style={{ maxWidth: '100%', maxHeight: '100%' }} src={imageUrl} /></div>)
                containsImage = true
              }
            }
          }
        }
        if (!containsImage) {
          columnContent.push(<h4 key='noimagesfound' style={{ marginLeft: '5%', color: palette.text.main }}>{t('productDetailsModal.noImageAvailable')}</h4>)
        } else {
          columnContent.push(<div key='imageDiv' style={{ display: 'flex', flexDirection: 'column', marginLeft: '10%', overflow: 'auto', position: 'relative', height: '75vh', width: '30vh' }}>{imageDiv}</div>)
        }

        rowDiv.push(<div key='flexcolumn' style={{ display: 'flex', flexDirection: 'row' }}>{columnContent}</div>)

        content.push(<div key='flexcolumn' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '75vh' }}>{rowDiv}</div>)
      } else if (!this.state.imageModal && !this.state.tableModal && !this.state.cicModal && !this.state.tradeItemRecordsModal && !this.state.isTradeItemHierarchyModalOpen && !this.state.tradeItemWithdrawalModal && !this.state.documentModal) {
        content.push(<h2 key='1'>{t('productDetailsModal.content')}</h2>)
        if (this.state.toggleXMLModal && !this.state.treeView) {
          if (this.state.isEuvino || this.state.isLidl || this.state.isKaufland || this.state.areOtherOrigions) {

            divContent.push(<ReactJson key='jsonContent' src={JSON.parse(this.state.jsonContent)} />)
          } else {
            divContent.push(this.state.treeList)
          }
        } else if (this.state.toggleXMLModal && this.state.treeView) {
          if (this.state.isEuvino || this.state.isLidl) {
            if (this.state.isLidl) {
              divContent.push(<ReactJson key='lidlMappedJson' src={JSON.parse(this.state.lidlMappedJson)} />)
            } else if (this.state.isEuvino) {
              if (Object.keys(this.state.originalJson).length !== 0) {
                divContent.push(<ReactJson key='offJson' src={JSON.parse(this.state.originalJson)} />)
              } else {
                let jsonString = this.state.jsonContent
                let jsonPretty = JSON.stringify(JSON.parse(jsonString), null, 2);
                let preTag = []
                preTag.push(<pre>{jsonPretty}</pre>)
                divContent.push(preTag)
              }
            }
          } else {
            divContent.push(<XMLViewer key='5' xml={this.state.xmlContent} theme={customTheme} />)
          }
        }
        content.push(<div key='2' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '79vh' }}>{divContent}</div>)

      }
      else if (this.state.imageModal) {
        content.push(<h2 key='3'>{t('productDetailsModal.images')}</h2>)
        if (this.state.imgList) {
          for (let i = 0; i < this.state.imgList.length; i++) {
            if (this.state.imgList[i]['referencedFileTypeCode'] === 'PRODUCT_IMAGE') {
              let columnContent = []
              if ('uniformResourceIdentifier' in this.state.imgList[i]) {
                columnContent.push(<div key={'div' + i} style={{ margin: 'auto', width: '300px', height: '300px' }}><img key={'image ' + i} alt="urlimage" onError={(e) => {if(['tif', 'tiff'].includes(this.state.imgList[i]['uniformResourceIdentifier'].split('.').slice(-1)[0])){e.target.src = require('../assets/img/No_Preview_image.png')} else this.handleBrokenImage(e)}} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100%', marginRight: '3%' }} src={this.state.imgList[i]['uniformResourceIdentifier']} 
                onClick={() => window.open(this.state.imgList[i]['uniformResourceIdentifier'], "_blank")} /></div>)
              }
              if (this.state.imgList[i]['referencedFileTypeCode'] !== '') {
                columnContent.push(<Label key={'label' + i} style={{ marginTop: '3%' }}>{'Typ: ' + this.state.imgList[i]['referencedFileTypeCode']}</Label>)
              }
              if ('fileName' in this.state.imgList[i] && this.state.imgList[i]['fileName'] !== '') {
                columnContent.push(<Label key={'labelname' + i}>{'File name: ' + this.state.imgList[i]['fileName']}</Label>)
              }
              divContent.push(<div key={'column' + i} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '10%', marginTop: '5%' }}>{columnContent}</div>)
              if (!('referencedFileTypeCode' in this.state.imgList[i]) && !('fileName' in this.state.imgList[i])) {
                columnContent.push(<Label key={'label2'}>Keine Information verfügbar.</Label>)
              }
            }
          }
          gridContent.push(<div key={'grid'} style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>{divContent}</div>)
        }
        content.push(<div key='4' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '70vh', marginTop: '5%' }}>{gridContent}</div>)
      } else if (this.state.documentModal) {
        content.push(<h2 key='3'>{t('productDetailsModal.documents')}</h2>)
        if (this.state.imgList) {
          for (let i = 0; i < this.state.imgList.length; i++) {
            if (!['PRODUCT_IMAGE', 'WEBSITE', 'PRODUCT_WEBSITE', 'RECIPE_WEBSITE'].includes(this.state.imgList[i]['referencedFileTypeCode'])) {
              let columnContent = []
              if ('uniformResourceIdentifier' in this.state.imgList[i]) {
                const fileUrl = this.state.imgList[i]['uniformResourceIdentifier'];
                const fileExtension = fileUrl.split('.').pop().toLowerCase();
                
                // Determine whether it's an image or a PDF based on the file extension
                const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
                
                columnContent.push(
                  <div key={'div' + i} style={{ margin: 'auto', marginBottom: '0px', width: '150px', height: '150px' }}>
                      <button onClick={() => isImage ? window.open(fileUrl, '_blank') : this.PDFHandler(fileUrl, i)}>
                          <img 
                              key={'image ' + i} 
                              alt="urlimage"
                              onError={(e) => { e.target.src = require('../assets/img/document.png') }} 
                              style={{ cursor: 'pointer', maxWidth: '80%', maxHeight: '80%', marginRight: '3%' }} 
                              src={fileUrl} 
                          />
                      </button>
                      <div style={{ textAlign: 'center', display: 'none', marginTop: '6%' }} id={"circleLoading" + i}>
                          <CircularProgress disableShrink size={30} />
                      </div>
                      <Typography 
                          style={{ textAlign: 'center', display: 'none', marginTop: '6%', color: palette.error.main }} 
                          id={"errorText" + i}>
                      </Typography>
                  </div>
                );
              }
              if (this.state.imgList[i]['referencedFileTypeCode'] !== '') {
                columnContent.push(<Label key={'label' + i} style={{ marginTop: '3%' }}>{'Type: ' + this.state.imgList[i]['referencedFileTypeCode']}</Label>)
              }
              if ('fileName' in this.state.imgList[i] && this.state.imgList[i]['fileName'] !== '') {
                columnContent.push(<Label key={'labelname' + i}>{'File name: ' + this.state.imgList[i]['fileName']}</Label>)
              }
              divContent.push(<div key={'column' + i} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '10%', marginTop: '5%' }}>{columnContent}</div>)
              if (!('referencedFileTypeCode' in this.state.imgList[i]) && !('fileName' in this.state.imgList[i])) {
                columnContent.push(<Label key={'label2'}>Keine Information verfügbar.</Label>)
              }
            }
          }
          gridContent.push(<div key={'grid'} style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>{divContent}</div>)
        }
        content.push(<div key='4' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '70vh', marginTop: '5%' }}>{gridContent}</div>)
      }
    }
    return content
  }

  toggleTree() {
    this.setState({ treeView: !this.state.treeView })
    this.setState({ imageModal: false, tableModal: false, documentModal: false })
  }

  generateXMLContent(idx) {
    let source = process.env.REACT_APP_SOURCE
    if (source === 'migros') {
      this.generateMigrosXMLContent()
    } else {
      this.generateOtherOriginsXMLContent()
    }
  }

  generateMigrosXMLContent(idx) {
    this.setState({
      toggleXMLModal: !this.state.toggleXMLModal,
      imageModal: false,
      tableModal: true,
      treeView: true,
      productDetails: {},
      disableImageButton: true,
      isEuvino: false,
      isLidl: false,
      isKaufland: false,
      areOtherOrigions: false,
      documentModal: false
    });
    if (!this.state.toggleXMLModal) {
      this.setState({
        fileName: this.state.records[idx]["gtin"],
        xmlContent: this.state.records[idx]["content"],
        images: this.state.records[idx]["images"],
        imageNames: this.state.records[idx]["image_names"],
        imageTypes: this.state.records[idx]["image_types"],
        index: idx,
      });
      if (!this.state.records[idx]["images"]) {
        this.setState({
          disableImageButton: true
        })
      } else {
        if (this.state.records[idx]["images"].length > 0) {
          this.setState({
            disableImageButton: false
          })
        }
      }
      if (this.state.treeView) {
        let json = ''
        let list = []
        xml2js.parseString(this.state.records[idx]["content"], function (err, result) {
          json = result;
        });
        if (['trustbox', 'datanature'].includes(this.state.records[idx]["origin"])) {
          list.push(<ul key='tree' className="tree">{traverseJson(json['product'])}</ul>)
        } else if (['gdsn', 'markant'].includes(this.state.records[idx]["origin"])) {
          list.push(<ul key='tree' className="tree">{traverseJson(json['tradeItem'])}</ul>)
        }
        this.setState({ jsonContent: json, treeList: list })

        this.generateProductDetails(this.state.records[idx]["content"], this.state.records[idx]["origin"])
      }
    } else {
      this.setState({
        xmlContent: '',
        treeView: true
      });
    }
  }

  toggleListing(idx) {
    this.setState({ isUserListingReadOnly: true })
    let id = this.state.idList[idx]
    let listingDict = this.state.listedDict
    let listing = !listingDict[id]
    listingDict[id] = listing
    this.setState({ listedDict: listingDict })
    this.context.apiService.postWithUIToken('update-listing/', JSON.stringify({ id: id, listing: listing.toString() }))
    .then(res => {
      if (process.env.REACT_APP_SOURCE === 'radeberger' && listing) {
        this.context.apiService.postWithUIToken('export-gdsn-artikel-pass/', JSON.stringify({ id: id, language: this.state.language} ))
          this.setState({ isUserListingReadOnly: false })
      } else {
        this.setState({ isUserListingReadOnly: false })
      }
    });
  }

  initiateDataRequestModal() {
    const itemIds = this.state.schwarzSelectedItems;
    for (let i=0; i < itemIds.length; i++) {
      const itemId = itemIds[i]
      const details = this.state.records[itemId]
      
      let jsonObject = JSON.parse(details['originalJson'])
      let shortDescription = this.findByKey(jsonObject, 'descriptionShort')
      if (shortDescription !== undefined) {
        shortDescription = shortDescription[0]['__value__']
      }
      
      const informationProviderOfTradeItem = this.findByKey(details, 'informationProviderOfTradeItem')
      const item = {
        gln: this.findByKey(informationProviderOfTradeItem, 'gln'),
        tax_number: '',
        partner_name: '',
        gtin: this.findByKey(details, 'gtin'),
        description: (shortDescription !== undefined) ? shortDescription : '',
        article_type: '',
        ekg: '',
        target_market: details['targetMarket']['targetMarketCountryCode']
      }
      this.state.schwarzSelectedItemList.push(item);
    }

    const uniqTargetMarkets = getDistinctValues(this.state.schwarzSelectedItemList, 'target_market')
    let countryCode = 'DE'
    if (uniqTargetMarkets && uniqTargetMarkets.length == 1) {
      const targetMarket = uniqTargetMarkets[0]
      countryCode = countryCodes[targetMarket]
      countryCode = countryCode ? countryCode : "DE"
    }
    this.setState({ markantFormCountryCode: countryCode })
    this.setState({ toggleDataRequestModal: !this.state.toggleDataRequestModal })
  }

  handleDataRequestModalClose() {
    this.setState({
      toggleDataRequestModal: !this.state.toggleDataRequestModal
    })

    this.setState({
      schwarzSelectedItemList: []
    })

  }

  selectAllCheckBoxes() {
    let listingIndexes = this.state.listedIndexes
    this.setState({ selectAllItems: !this.state.selectAllItems })
    if (!this.state.selectAllItems) {
      for (let i = 0; i < this.state.idList.length; i++) {
        listingIndexes[i] = true
      }
    } else {
      for (let i = 0; i < this.state.idList.length; i++) {
        listingIndexes[i] = false
      }
    }
    let selectedIdx = Object.keys(listingIndexes).filter((key) => listingIndexes[key] === true);
    this.setState({schwarzSelectedItems: selectedIdx})
    this.setState({ listedIndexes: listingIndexes })
  }

  schwarzCheckBoxClick(e, idx) {
    let listingIndexes = this.state.listedIndexes
    if ( e.target.checked ) {
      listingIndexes[idx] = true
    } else {
      listingIndexes[idx] = false
    }
    let selectedIdx = Object.keys(listingIndexes).filter((key) => listingIndexes[key] === true);
    this.setState({schwarzSelectedItems: selectedIdx})
    this.setState({ listedIndexes: listingIndexes })
  }

  findByKey = (obj, kee) => {
      if (kee in obj) return obj[kee];
      for(let n of Object.values(obj).filter(Boolean).filter(v => typeof v === 'object')) {
          let found = this.findByKey(n, kee)
          if (found) return found
      }
  }

  removeItemFromArray(input_array, value) {
    var index = input_array.indexOf(value);
    if (index > -1) {
      input_array.splice(index, 1);
    }
    return input_array;
  }

  listAllItems() {
    this.setState({ isUserListingReadOnly: true })
    let listingDict = this.state.listedDict
    let listing = ''
    this.setState({ selectAllItems: !this.state.selectAllItems })
    if (!this.state.selectAllItems) {
      for (let i = 0; i < this.state.idList.length; i++) {
        listingDict[this.state.idList[i]] = true
      }
      listing = 'true'
    } else {
      for (let i = 0; i < this.state.idList.length; i++) {
        listingDict[this.state.idList[i]] = false
      }
      listing = 'false'
    }
    this.setState({ listedDict: listingDict })
    this.context.apiService.postWithUIToken('update-all-listing/', JSON.stringify({ idList: this.state.idList, listing: listing }))
    .then(res => {
      this.setState({ isUserListingReadOnly: false })
    });
  }

  generateOtherOriginsXMLContent(idx) {
    this.setState({
      toggleXMLModal: !this.state.toggleXMLModal,
      imageModal: false,
      tableModal: true,
      treeView: true,
      productDetails: {},
      isEuvino: false,
      isLidl: false,
      isKaufland: false,
      areOtherOrigions: true,
      documentModal: false,
      disableImageButton: true,
      disableDocumentButton: true
    });

    if (!this.state.toggleXMLModal) {
      this.setState({
        fileName: this.state.records[idx]["gtin"],
        xmlContent: this.state.records[idx]["originalXml"],
        imgList: this.state.records[idx]["referencedFileHeader"],
        index: idx,
      });
      if (!this.state.records[idx]["referencedFileHeader"]) {
        this.setState({
          disableImageButton: true,
          disableDocumentButton: true
        })
      } else {
        if (this.state.records[idx]["referencedFileHeader"].length > 0) {
          let containsImage = false
          let containsDocument = false
          for (let i = 0; i < this.state.records[idx]["referencedFileHeader"].length; i++) {
            if (this.state.records[idx]["referencedFileHeader"][i]['referencedFileTypeCode'] === 'PRODUCT_IMAGE') {
              containsImage = true
              break
            }
          }
          for (let i = 0; i < this.state.records[idx]["referencedFileHeader"].length; i++) {
            if (!['PRODUCT_IMAGE', 'WEBSITE', 'RECIPE_WEBSITE', 'PRODUCT_WEBSITE'].includes(this.state.records[idx]["referencedFileHeader"][i]['referencedFileTypeCode'])) {
                containsDocument = true
              break
            }
          }

          if (containsImage) {
            this.setState({ disableImageButton: false })
          } 
          if (containsDocument) {
            this.setState({ disableDocumentButton: false })
          } 
        }
      }

      let json = ''
      let list = []
      xml2js.parseString(this.state.records[idx]["originalXml"], function (err, result) {
        json = result;
      });
      if (['trustbox'].includes(this.state.records[idx]["source"])) {
        list.push(<ul key='tree' className="tree">{traverseJson(json['Product'])}</ul>)
      } else if (['GDSN', 'markant'].includes(this.state.records[idx]["source"])) {
        list.push(<ul key='tree' className="tree">{traverseJson(json['tradeItem'])}</ul>)
      }
      this.setState({ jsonContent: this.state.records[idx]["originalJson"], treeList: list })

      // @TODO Remove schwarz related condition after decommissioning the Schwarz platform
      let source = process.env.REACT_APP_SOURCE
      let mode = process.env.REACT_APP_MODE
      let contentLanguage = ''

      if (source === 'schwarz' && mode !== 'mvp' && mode !== 'a' && mode !== 'q' && mode !== 'lidl') {
        this.generateProductDetails(this.state.records[idx]["originalXml"], this.state.records[idx]["source"])
      } else {
        // // Get the details from Elastic Search
        const productId = this.state.records[idx]['productId']
        const source = this.state.records[idx]["source"];
        const labelLanguage = this.state.language
        if (source === 'schwarz' && ['mvp', 'a', 'q', 'lidl'].includes(mode)) {
          contentLanguage = this.state.records[idx]["countryCodeAlpha2"].toLowerCase();
        } else {
          contentLanguage = labelLanguage
        }  
        this.getProductDetailsFromEs(productId, source, contentLanguage, labelLanguage)
      }
    } else {
      this.setState({
        xmlContent: '',
        treeView: true,
        disableImageButton: true,
        disableDocumentButton: true
      });
    }
  }

  openXmlDownloadDropdown = (event) => {
    this.setState({
      anchorElXmlDownload: event.currentTarget
    });
  };

  closeXmlDownloadDropdown = () => {
    this.setState({
      anchorElXmlDownload: null
    });
  };

  downloadXML() {
    let source = process.env.REACT_APP_SOURCE; 
    exportProductXML(this.state.records[this.state.index])
    if(source === 'aldi'){
      this.close_xml_download_dropdown();
    }
  }

  close_xml_download_dropdown = () => {
    this.setState({
      anchorEl_xmlDownload: null
    });
  };

  updateInformaticaXMLMenuItemDisableStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, disableInformaticaXMLMenuItem: status } : record
      )
    }));
  }

  updateInformaticaXMLLinearProgressStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, hideInformaticaXMLLinearProgress: status } : record
      )
    }));
  }

  downloadInformaticaXML(recordIndex){
    let source = process.env.REACT_APP_SOURCE
    if(source!=='aldi'){
      return alert('Error: source mismatch')
    }

    this.updateInformaticaXMLMenuItemDisableStatus(recordIndex, true)
    this.updateInformaticaXMLLinearProgressStatus(recordIndex, false)

    let fileName = `Informatica XML ${this.state.records[recordIndex]['gtin']}`;
    this.handleSnackBarOpen(fileName);

    const id = this.state.records[recordIndex]["productId"]
    this.context.apiService.getInformaticaXML(id)
      .then(res => res.json())
      .then(res => {
        let xml_data = res['data'];
        let file_name = res['file_name'];
        var blob = new Blob([xml_data], { type: 'text/xml' });
        var url = URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', file_name + '.xml');
        tempLink.click();
        URL.revokeObjectURL(url)

        this.updateInformaticaXMLLinearProgressStatus(recordIndex, true)
        this.setState({snackBarOpen: false})
        this.updateInformaticaXMLMenuItemDisableStatus(recordIndex, false)
      });
  }  
  
  downloadJSON() {
    exportProductJSON(this.state.records[this.state.index])
  }

  updateCINMenuItemDisableStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, disableCINMenuItem: status } : record
      )
    }));
  }

  updateMessageDownloadMenuItemDisableStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, disableMessageDownloadMenuItem: status } : record
      )
    }));
  }

  updateCINLinearProgressStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, hideCINLinearProgress: status } : record
      )
    }));
  }

  updateMessageDownloadLinearProgressStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, hideMessageDownloadLinearProgress: status } : record
      )
    }));
  }

  downloadCIN(recordIndex){
    let fileName = ''
    this.updateCINMenuItemDisableStatus(recordIndex, true)
    this.updateCINLinearProgressStatus(recordIndex, false)

    fileName = `CIN XML ${this.state.records[recordIndex]['gtin']}`;
    this.handleSnackBarOpen(fileName);
    
    let jsonString = this.state.records[recordIndex]["originalJson"]
    this.context.apiService.getCinXML(jsonString)
      .then(res => {
        let contentDisposition = res.headers.get('content-disposition').split(';')[1]
        fileName = contentDisposition.split('=')[1]
        return res.blob();
    }).then(blob => {
        if (blob !== undefined) {
            if (blob instanceof Blob) {
                saveAs(blob, fileName);
                this.updateCINLinearProgressStatus(recordIndex, true)
                this.setState({snackBarOpen: false})
                this.updateCINMenuItemDisableStatus(recordIndex, false)
            } else if ('responseDescription' in blob) {
                alert(blob['responseDescription'])
            }
        }
    }).catch(err => console.error(err));
  }

  updateKArticlePassMenuItemDisableStatus(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, disableKArticlePassMenuItem: status } : record
      )
    }));
  }

  updateKArticlePassLinearProgress(recordIndex, status){
    this.setState(prevState => ({
      records: prevState.records.map((record, index) =>
        recordIndex === index ? { ...record, hideKArticlePassLinearProgress: status } : record
      )
    }));
  }

  downloadKauflandArticlepassMoreButton(recordIndex){
    this.updateKArticlePassMenuItemDisableStatus(recordIndex, true)
    this.updateKArticlePassLinearProgress(recordIndex, false)

    const id = this.state.records[recordIndex]["productId"]
    let fileName = ''
    
    fileName = `Kaufland ArticlePass ${this.state.records[recordIndex]['gtin']}`;
    this.handleSnackBarOpen(fileName);

    this.context.apiService.getKauflandArticlepass(id)
      .then(res => {
        if (res.ok) {
            let contentDisposition = res.headers.get('content-disposition').split(';')[1]
            fileName = contentDisposition.split('=')[1]
            return res.blob();
        }
      }).then(blob => {
        if (blob !== undefined) {
            if (blob instanceof Blob) {
                saveAs(blob, fileName);

                this.updateKArticlePassMenuItemDisableStatus(recordIndex, false)
                this.setState({snackBarOpen: false})
                this.updateKArticlePassLinearProgress(recordIndex, true)
            }
        }
    }).catch(err => console.error(err));
  }

  downloadOriginalMessage(recordIndex){

    this.updateMessageDownloadMenuItemDisableStatus(recordIndex, true)
    this.updateMessageDownloadLinearProgressStatus(recordIndex, false)
    let fileName = ''
    
    const id = this.state.records[recordIndex]["productId"]
    const source = this.state.records[recordIndex]["source"]
    const gtin = this.state.records[recordIndex]['gtin']

    let message = `Message download started for GTIN: ${gtin}`;
    this.handleSnackBarOpen(message);

    this.context.apiService.downloadOriginalMessage(id, source)
      .then(res => {
        if (res.ok) {
          let contentDisposition = res.headers.get('content-disposition').split(';')[1]
          fileName = contentDisposition.split('=')[1]
          return res.blob();
        } else {
          message = `Error while downloading message for GTIN: ${gtin}`
          this.handleSnackBarOpen(message, 'error');
          this.updateMessageDownloadLinearProgressStatus(recordIndex, true)
          this.updateMessageDownloadMenuItemDisableStatus(recordIndex, false)
        }
      }).then(blob => {
        if (blob !== undefined) {
          if (blob instanceof Blob) {
            saveAs(blob, `${fileName}`);
            this.updateMessageDownloadLinearProgressStatus(recordIndex, true)
            this.setState({snackBarOpen: false})
            this.updateMessageDownloadMenuItemDisableStatus(recordIndex, false)
          }
        }
    }).catch(err => console.error(err));
  }

  generateEuvinoContent(idx) {
    this.setState({
      toggleXMLModal: !this.state.toggleXMLModal,
      imageModal: false,
      tableModal: true,
      treeView: true,
      productDetails: {},
      isEuvino: true,
      isLidl: false,
      isKaufland: false,
      areOtherOrigions: false,
      documentModal: false
    });
    if (!this.state.toggleXMLModal) {
      this.setState({
        fileName: this.state.records[idx]["gtin"],
        index: idx,
        imgList: this.state.records[idx]["referencedFileHeader"],
      });
      if (!this.state.records[idx]["referencedFileHeader"]) {
        this.setState({
          disableImageButton: true,
          imgList: null
        })
      } else {
        if (this.state.records[idx]["referencedFileHeader"].length > 0) {
          let containsImage = false
          for (let i = 0; i < this.state.records[idx]["referencedFileHeader"].length; i++) {
            if (this.state.records[idx]["referencedFileHeader"][i]['referencedFileTypeCode'] === 'PRODUCT_IMAGE') {
              containsImage = true
              break
            }
          }
          if (containsImage) {
            this.setState({
              disableImageButton: false
            })
          } else {
            this.setState({
              disableImageButton: true
            })
          }
        }
      }
      let json = ''
      json = this.state.records[idx]['originalJson'];
      let originalJson = {}
      originalJson = JSON.parse(json)
      // Fetch Open Food Facts JSON
      if ('originalJson' in originalJson) {
        this.setState({ originalJson: originalJson['originalJson'] })
        originalJson = Object.assign({}, originalJson);
        delete originalJson['originalJson']
        if ('thumbnail' in originalJson) {
          delete originalJson['thumbnail']
        }
        json = JSON.stringify(originalJson)
      }
      this.setState({ jsonContent: json })
      const productId = this.state.records[idx]['productId']
      const source = this.state.records[idx]["source"];
      let mode = process.env.REACT_APP_MODE;
      
      let contentLanguage = ''
      if (source === 'schwarz' && ['mvp', 'a', 'q', 'lidl'].includes(mode)) {
        contentLanguage = this.state.records[idx]["countryCodeAlpha2"].toLowerCase();
      } else {
        contentLanguage = this.state.language
      }
      const labelLanguage = this.state.language
      
      this.getProductDetailsFromEs(productId, source, contentLanguage, labelLanguage)
    } else {
      this.setState({
        xmlContent: '',
        treeView: true
      });
    }
  }

  /**
   * For GDSN Subscription
   */

  toggleGdsnSubscriptionModal() {
    this.setState({
      isGdsnSubscriptionModalDisplayed: !this.state.isGdsnSubscriptionModalDisplayed
    })
  }

  specifyQuery(query) {
    this.setState({
      inputValue: query
    })
    this.searchKeyword(query);
  }

  /**
   * For Euvino Subscription
   */

  languageOptions() {
    let languages = [{ name: 'Deutsch', code: 'de' }, { name: 'English', code: 'en' }]
    if(process.env.REACT_APP_SOURCE === 'schwarz') {
      if (['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE)) {
        languages.push({ name: 'Български', code: 'bg' })
        languages.push({ name: 'Česky', code: 'cz' })
        languages.push({ name: 'Românesc', code: 'ro' })
        languages.push({ name: 'Slovenská', code: 'sk' })
        languages.push({ name: 'Polski', code: 'pl' })
        languages.push({ name: 'Hrvatski', code: 'hr' })
      }
    } else {
      languages.push({ name: 'French', code: 'fr' })
    }
    return languages.map((dt, i) => {
      return (
        <MenuItem
          label="Select a langauge"
          value={dt.code}
          key={i} name={dt.name}>{dt.name}
        </MenuItem>
      );
    });
  }

  productDetailsLanguageOptions() {
    let languages = [{ name: 'Deutsch', code: 'de' }, { name: 'English', code: 'en' }]
    if(process.env.REACT_APP_SOURCE === 'schwarz') {
      if (['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE)) {
        languages.push({ name: 'Български', code: 'bg' })
        languages.push({ name: 'Česky', code: 'cz' })
        languages.push({ name: 'Românesc', code: 'ro' })
        languages.push({ name: 'Slovenská', code: 'sk' })
        languages.push({ name: 'Polski', code: 'pl' })
        languages.push({ name: 'Hrvatski', code: 'hr' })
      }
    } else {
      languages.push({ name: 'French', code: 'fr' })
    }
    return languages.map((dt, i) => {
      return (
        <MenuItem
          label="Select a langauge"
          value={dt.code}
          key={i} name={dt.name}>{dt.name}
        </MenuItem>
      );
    });
  }

  changeLanguageEvent = (event) => {
        this.changeLanguage(event.target.value);
    // localStorage.setItem('language', event.target.value);
    this.setState({
      language: event.target.value,
      languageChanged: true,
      showLanguageDropdown: false
    }, () => {this.saveGPCSettings(); this.saveSettings(process.env.REACT_APP_SOURCE)})
  }

  onSearchQueryFilterChange = (event) => {

    const dropDownList = searchQueryFilterItems()

    const selectedValue = event.target.value
    this.setState({searchQueryFilter: event.target.value})

    let placeHolder = "Search in All Fields"
    if (selectedValue in dropDownList) {
      placeHolder = `Search in ${dropDownList[selectedValue]}`
    }
    this.setState({searchInputPlaceHolder: placeHolder})
  }

  productDetailsChangeLanguageEvent = (event) => {
    const productId = this.state.productId
    const source = this.state.dataSource;
    const contentLanguage = event.target.value.toLowerCase();
    const labelLanguage = this.state.language
    this.getProductDetailsFromEs(productId, source, contentLanguage, labelLanguage)
    this.setState({productDetailsLanguage: event.target.value})
  }

  changeLanguage(lang) {
    i18next.changeLanguage(lang);
  }

  showLanguageOptions = (e) => {
    e.preventDefault()
    this.setState({
      showLanguageDropdown: true
    })
  }

  hideLanguageOptions = (e) => {
    e.preventDefault()
    this.setState({
      showLanguageDropdown: false
    })
  }

  toggleEuvinoSubscriptionModal() {
    this.setState({
      isEuvinoSubscriptionModalDisplayed: !this.state.isEuvinoSubscriptionModalDisplayed
    })
  }

  /**
   * For Export
   */

  toggleExportModal() {
    this.setState({
      isExportModalDisplayed: !this.state.isExportModalDisplayed
    })
  }

  loadSavedSettings(source){
  if(JSON.parse(localStorage.getItem('currentSettings'))[source]) {
      let currentSettings = JSON.parse(localStorage.getItem('currentSettings'))[source]
      if(currentSettings['language']) {
        this.changeLanguage(currentSettings['language'])
        this.setState({language: currentSettings['language']})
      }
      if(currentSettings['size']) this.setState({size: currentSettings['size']})
      if(currentSettings['isConsumerUnit']) this.setState({isConsumerUnit: currentSettings['isConsumerUnit']})
      if(currentSettings['isBaseUnit']) this.setState({isBaseUnit: currentSettings['isBaseUnit']})
      if(currentSettings['childTradeItemRadio']) this.setState({childTradeItemRadio: currentSettings['childTradeItemRadio']})
      if(currentSettings['exactSearch']) this.setState({exactSearch: currentSettings['exactSearch']})
      if(currentSettings['logicalOperator']) this.setState({logicalOperator: currentSettings['logicalOperator']})
      if(currentSettings['listedFilter']) this.setState({listedFilter: currentSettings['listedFilter']})
    }
  }
  
  updateTargetMarketSwitches(targetMarketSwitchesOld, targetMarketSwitches) {
    let i = 0
    for (const item of targetMarketSwitchesOld) {
      for (const [key, value] of Object.entries(item)) {
        if (keyExists(targetMarketSwitches, key)) {
          targetMarketSwitches[i][key] = value;
        }
      }
      i=i+1;
    }
    return targetMarketSwitches
  }  

  saveSettings(source){
    if(JSON.parse(localStorage.getItem('currentSettings'))[source]){
      let currentSettings = JSON.parse(localStorage.getItem('currentSettings'))
      currentSettings[source]['language'] = this.state.language
      currentSettings[source]['size'] = this.state.size
      currentSettings[source]['isConsumerUnit'] = this.state.isConsumerUnit
      currentSettings[source]['isBaseUnit'] = this.state.isBaseUnit
      currentSettings[source]['childTradeItemRadio'] = this.state.childTradeItemRadio
      currentSettings[source]['exactSearch'] = this.state.exactSearch
      currentSettings[source]['logicalOperator'] = this.state.logicalOperator
      currentSettings[source]['listedFilter'] = this.state.listedFilter

      if(this.state.sourceSwitches.length > 0) {
        currentSettings[source]['sourceSwitches'] = this.state.sourceSwitches
      }
      if (this.state.targetMarketSwitches.length > 0) {
        currentSettings[source]['targetMarketSwitchesOld'] = this.state.targetMarketSwitches
      }
    localStorage.setItem('currentSettings', JSON.stringify(currentSettings))
    }    
      }

  saveGPCSettings(){
    let newGpcFilter = ''
    let newGpcFilterLst = []
    let newSelectedGpcFilterLst = []   
      
    if (this.state.gpcFilterList.length > 0) {
      for (let i = 0; i < this.state.gpcFilterList.length; i++) {
        newGpcFilter = this.modifyFilter(this.state.gpcFilterList[i],newSelectedGpcFilterLst)
        newGpcFilterLst[i] = newGpcFilter
      }        
    }
    this.setState({gpcFilterList : newGpcFilterLst})    
  }
  
  modifyFilter(filter, newSelectedGpcFilterLst){
    let brickDescription = ''
    let modFilter = ''
    let gpcBrickCode = ''
    let source = process.env.REACT_APP_SOURCE;
    let mode = process.env.REACT_APP_MODE;
    filter = filter.split(' - ')[0]
    if (this.state.records !== undefined && this.state.records !== null) {
      for (let i = 0; i < this.state.records.length; i++) {
        gpcBrickCode = getBrickCode(this.state.records[i])
        if(filter === gpcBrickCode){
          if(source !== 'migros'){
          if(source === 'schwarz'){ 
            if (['mvp', 'a', 'q', 'lidl'].includes(mode)){
              brickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
            }else{
              brickDescription = gpcLocalization(this.state.records[i], this.state.language)
            }
          }else{
            brickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
          }            
          }else{
            brickDescription = gpcLocalization(this.state.records[i], this.state.language)
          }          
          modFilter = gpcBrickCode + ' - ' + brickDescription 
          this.saveNewSelectedGpcFilterLst(newSelectedGpcFilterLst, modFilter) 
          this.setState({gpcSelectedFilterList : newSelectedGpcFilterLst})
          return modFilter 
        }                  
      }
    }

    for (let i = 0; i < this.state.originalRecordList.length; i++) {
      gpcBrickCode = getBrickCode(this.state.originalRecordList[i])
      if(filter === gpcBrickCode){
        if(source !== 'migros'){
        if(source === 'schwarz'){ 
          if (['mvp', 'a', 'q', 'lidl'].includes(mode)){
            brickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
          }else{
            brickDescription = gpcLocalization(this.state.originalRecordList[i], this.state.language)
          }
        }else{
          brickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,this.state.completeGPCData,this.state.language,defaultLanguage)
        }         
        }else{
          brickDescription = gpcLocalization(this.state.originalRecordList[i], this.state.language)
        }         
        modFilter = gpcBrickCode + ' - ' + brickDescription
        this.removeSelectedGpcFilter(newSelectedGpcFilterLst,modFilter)
        this.setState({gpcSelectedFilterList : newSelectedGpcFilterLst})
        return modFilter 
      }         
    }
  }

  saveNewSelectedGpcFilterLst(newSelectedGpcFilterLst,modFilter) {
    if(newSelectedGpcFilterLst.length > 0){
      newSelectedGpcFilterLst[newSelectedGpcFilterLst.length] = modFilter
    }else{
      newSelectedGpcFilterLst[0] = modFilter
    }     
  }
  
  removeSelectedGpcFilter(newSelectedGpcFilterLst,modFilter) {
    if(newSelectedGpcFilterLst.length > 0){
      newSelectedGpcFilterLst = newSelectedGpcFilterLst.filter(e => e !== modFilter)
    }
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    this.setState({snackBarOpen: false})
  };

  handleSnackBarOpen = (message, severity='success') => {
    if (message) {
      this.setState({snackBarSeverity: severity})
      this.setState({snackBarMessage: message});
      this.setState({snackBarOpen: true})
      this.setState({snackBarDuration: 6000});
    }
  };

  render() {
    const { t } = this.props;
    let source = process.env.REACT_APP_SOURCE;
    return (
      <>
      <MuiThemeProvider theme={theme}>
        {localStorage.setItem('saved', new Date().getTime())}
        <div className="content">
          {/* Search result item snack bar */}
          <Snackbar open={this.state.snackBarOpen} autoHideDuration={this.state.snackBarDuration} onClose={this.handleSnackBarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
            <Alert onClose={this.handleSnackBarClose} severity={this.state.snackBarSeverity} color={this.state.snackBarSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.handleSnackBarClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {process.env.REACT_APP_SOURCE === 'schwarz' ?
                <>{this.state.snackBarMessage} {t('searchResults.snackBarMessageOnDownload')}</>
                : <>{this.state.snackBarMessage}</>
              }
            </Alert>
          </Snackbar>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%'}}>
                  <Grid container direction="row">
                    <Grid item>
                      <a href='/'>
                        {process.env.REACT_APP_SOURCE === 'schwarz' ? <img src={require('../assets/img/schwarz_logo.png')} alt="logo"/> : <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" height='78' />}
                      </a>
                    </Grid>
                    <Grid item>
                      {process.env.REACT_APP_MODE === 'test' && <div justifyContent="flex-start" style={{paddingLeft: '30px', paddingTop: '12px', paddingBottom: '10px'}}> <span style={{fontSize: 30, fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.text.light}}>{t('dashboard.testEnvironment')}</span></div>}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">
                      {this.context.isSubscriptionEnabled && <Grid item><Typography style={{ marginRight: '10px', fontWeight: 'bold', color: 'rgba(34, 42, 66, 0.7)' }}>{t('searchResults.requestMoreData')}: </Typography></Grid>}
                      {this.context.isSubscriptionEnabled && this.context.isEuvinoSubscriptionEnabled && <Grid item><Button variant="contained" color="primary" onClick={this.toggleEuvinoSubscriptionModal} style={{ outline: 'none', marginRight: '10px' }}>Euvino</Button></Grid>}
                      {this.context.isSubscriptionEnabled && <Grid item><Button variant="contained" color="primary" onClick={this.toggleGdsnSubscriptionModal} style={{ outline: 'none', marginRight: '10px' }}>GDSN</Button></Grid>}
                      {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) ?
                        <Grid item style={{marginRight: "10px", marginLeft: "10px"}}>
                          <Button color="primary" variant="contained"
                            onClick={() => this.initiateDataRequestModal()}
                            style={{ outline: 'none', float: 'right' }}>{t('markantDataRequestModal.dataRequest')} Markant</Button>
                        </Grid>
                      : null}
                      {((getCookie('user_role') === 'super' && process.env.REACT_APP_SOURCE !='radeberger') ? true : false) || (['prod', 'lidl'].includes(process.env.REACT_APP_MODE)) && process.env.REACT_APP_SOURCE !='radeberger' && this.context.isExportEnabled && <Grid item><Button variant="contained" color="primary" onClick={this.toggleExportModal} startIcon={<DescriptionIcon />} style={{ outline: 'none', marginRight: '10px' }}>{t('exportModal.export')}</Button></Grid>}
                      {this.context.isBasicAuthEnabled && <Grid item><Button variant="contained" color="primary" startIcon={<ExitToAppIcon />} onClick={this.logout} style={{ outline: 'none' }}>{t('searchResults.logOut')}</Button></Grid>}
                    </Grid>
                    <Grid container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">
                      <Grid item style={{ paddingTop: '10px' }}>
                      <Select MenuProps={ {getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}} disableUnderline value={this.state.language} onChange={this.changeLanguageEvent}>
                      {this.languageOptions()}
                      </Select>
                      </Grid>
                      {this.context.isBasicAuthEnabled && source !== 'schwarz' &&
                        <Grid item
                          style={{ paddingTop: '10px' }}>
                          <Button color="primary"
                            onClick={() => this.toggleChangePassword()}
                            style={{ outline: 'none', fontSize: '0.8rem', float: 'right' }}>{t('dashboard.changePassword')}</Button>
                        </Grid>}
                    </Grid>
                  </Grid>
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {(  ['aldi', 'bc', 'denner', 'flaschenpost', 'radeberger', 'migros'].includes(process.env.REACT_APP_SOURCE) ) ?
                        (<Grid container direction="row" >
                        <Grid item xs={2} sm={2} style={{ position: 'relative'}}>
                          <SearchQueryFilter selectedFilter = {this.state.searchQueryFilter} onFilterChange = {this.onSearchQueryFilterChange}/>
                        </Grid>
                        <Grid item xs={10} sm={10}>
                          <div className='inputWithButton withSearchFilterDropdown'>
                              <Input id="inlineFormInputGroup" placeholder={this.state.searchInputPlaceHolder} 
                              value={this.state.inputValue} style={{ borderColor: palette.primary.main, marginTop: '2%', width: '97%'}} type="text" 
                              onKeyPress={this.detectEnterKey} onChange={this.onSearchTextChange.bind(this)} />
                              <Button variant="contained" color="primary" onClick={this.handleSearch} style={{ outline: 'none'}}>Search</Button>
                              <Link to={this.state.language === 'de' ? '/help' : '/help-en'} target="_blank" style={{position:'absolute',right: "-0.5%",bottom: "25%"}}>
                            <IconButton type="button" style={{ outline: 'none' }}>
                              <HelpIcon color="primary" />
                            </IconButton>
                          </Link>
                          </div>
                        </Grid>
                        </Grid>)
                      : 
                        (
                          <> <div className='inputWithButton noSearchFilterDropdown'>
                            <Input id="inlineFormInputGroup" placeholder="Suchen" 
                            value={this.state.inputValue} style={{ borderColor: palette.primary.main, marginTop: '2%' }} type="text" 
                            onChange={this.onSearchTextChange} onKeyPress={this.detectEnterKey}  />
                            <Button variant="contained" color="primary" onClick={this.handleSearch} style={{ outline: 'none'}}>Search</Button>
                        </div>
                        <Link to={this.state.language === 'de' ? '/help' : '/help-en'} target="_blank" style={{ marginTop: '1.9%' }}>
                          <IconButton type="button" style={{ outline: 'none' }}>
                            <HelpIcon color="primary" />
                          </IconButton>
                        </Link> </>
                        )}
                      </div> 
                    </Grid>
                  </Grid>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1.5%', paddingRight: '2%' }}>
                  <Form style={{paddingRight: '0.5%', marginLeft: '0.5%'}}>
                    <CardTitle tag="h6">{t('searchResults.itemsPerPage')} </CardTitle>
                      <Label style={{ marginTop:'2%', marginLeft: '4%'}}>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="25" name="25" checked={this.state.size === "25"} onChange={this.handleSizeChange}/>
                      <span style={{whiteSpace: 'nowrap'}}>&nbsp;&nbsp;&nbsp;25</span>
                      </Label>
                      <Label style={{ marginTop:'2%', marginLeft: '8%'}}>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="50" name="50" checked={this.state.size === "50"} onChange={this.handleSizeChange}/>
                      <span>&nbsp;&nbsp;&nbsp;50</span>
                      </Label><br/>
                      <Label style={{ marginTop:'2%', marginLeft: '4%'}}>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="100" name="100" checked={this.state.size === "100"} onChange={this.handleSizeChange} />
                      <span style={{whiteSpace: 'nowrap'}}>&nbsp;100</span>
                      </Label>
                      <Label style={{ marginTop:'2%', marginLeft: '8%'}}>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="250" name="250" checked={this.state.size === "250"} onChange={this.handleSizeChange} />
                      <span style={{whiteSpace: 'nowrap'}}>&nbsp;250</span>
                      </Label>
                      <Label style={{ marginTop:'2%', marginLeft: '4%', marginRight: '8%' }}>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="500" name="500" checked={this.state.size === "500"} onChange={this.handleSizeChange} />
                      <span>&nbsp;500</span>
                      </Label>
                  </Form>
                  <Form style={{paddingRight: '0.5%'}}>
                    <CardTitle tag="h6">{t('searchResults.logicalOperator')}</CardTitle>
                    <Label style={{  marginTop:'2%', marginLeft: '4%'}}>
                    <input type="radio" style={{accentColor: palette.primary.main}} value="AND" name="AND" checked={this.state.logicalOperator === "AND"} onChange={this.handleLogicalOperatorRadioChange} />
                    <span>&nbsp;{t('searchResults.and_CAP')}</span>
                    </Label><br/>
                    <Label style={{  marginTop:'2%', marginLeft: '4%'}}>
                    <input type="radio" style={{accentColor: palette.primary.main}} value="OR" name="OR" checked={this.state.logicalOperator === "OR"} onChange={this.handleLogicalOperatorRadioChange} />
                    <span>&nbsp;{t('searchResults.or_CAP')}</span>
                    </Label>
                  </Form>
                  <Form style={{paddingRight: '0.5%'}}>
                    <CardTitle tag="h6" dangerouslySetInnerHTML={{__html: t('searchResults.searchExactTerms').replace(/\n/g, "<br />") }}></CardTitle>
                    <FormControlLabel
                      control={<Switch size="small" value="exactsearch" name="exactsearch" color="primary" checked={this.state.exactSearch} onChange={this.handleExactSearchChange} />}
                  /></Form>
                  <Form style={{paddingRight: '0.5%'}}>
                    <CardTitle tag="h6">{t('searchResults.searchIn')}</CardTitle>
                    <ThemeProvider theme={labelFontSize}>
                      {this.generateSourceSwitches(t)}
                    </ThemeProvider>
                  </Form>
                  {this.context.isChildGtinSwitchEnabled ?
                    <Form style={{paddingRight: '0.5%'}}>
                      <CardTitle tag="h6">{t('searchResults.searchFor')}</CardTitle>
                      <Label>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="base" name="base" checked={this.state.childTradeItemRadio === "base"} onChange={this.handleChildTradeItemRadioChange} />
                      <span>&nbsp;{t('searchResults.onlyTradeItems')}</span>
                      </Label><br />
                      <Label>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="child" name="child" checked={this.state.childTradeItemRadio === "child"} onChange={this.handleChildTradeItemRadioChange} />
                      <span>&nbsp;{t('searchResults.onlyChildTradeItems')}</span>
                      </Label><br />
                      <Label>
                      <input type="radio" style={{accentColor: palette.primary.main}} value="both" name="both" checked={this.state.childTradeItemRadio === "both"} onChange={this.handleChildTradeItemRadioChange} />
                      <span>&nbsp;{t('searchResults.bothItems')}</span>
                      </Label>
                    </Form>
                    : null}
                  <Form style={{paddingRight: '0.5%'}}>
                    <CardTitle tag="h6">{t('searchResults.targetMarket_CAP')}</CardTitle>
                    <ThemeProvider theme={labelFontSize}>
                      {this.generateTargetMarketSwitches(t)}
                    </ThemeProvider>
                  </Form>
                  {process.env.REACT_APP_SOURCE === 'radeberger' || process.env.REACT_APP_SOURCE === 'flaschenpost' ?
                    <Form style={{paddingRight: '0.5%'}}>
                      <CardTitle tag="h6">{t('searchResults.onlyListedItems')}</CardTitle>
                      <FormControlLabel style={{ marginLeft: '5%' }}
                        control={<Switch size="small" value="listed" name="listed" color="primary" checked={this.state.listedFilter} onChange={this.handleListedFilterChange} />}
                      />
                    </Form>
                    : null}
                  {process.env.REACT_APP_SOURCE !== 'markant-book' ?
                    <Form style={{paddingRight: '0.5%'}}>
                      <CardTitle tag="h6">{t('searchResults.searchScope')}</CardTitle>
                      <ThemeProvider theme={labelFontSize}>
                        <FormControlLabel
                          control={<Switch size="small" value="true" name="isBaseUnit" color="primary" checked={this.state.isBaseUnit} onChange={this.handleOnlyBaseUnitChange} />}
                          label={t('searchResults.onlyBaseUnit')} labelPlacement='end'
                        /><br/>
                        {process.env.REACT_APP_SOURCE !== 'schwarz' &&
                        <FormControlLabel
                          control={<Switch size="small" value="true" name="isTradeItemAConsumerUnit" color="primary" checked={this.state.isConsumerUnit} onChange={this.handleOnlyConsumerUnitChange} />}
                          label={t('searchResults.onlyConsumerItems')} labelPlacement='end'
                        />}
                      </ThemeProvider>
                    </Form>
                    : null }
                </div>
                {this.state.noRecordsFound ? <div style={{ display: 'flex', marginTop: '3%', marginLeft: '47%' }}><Label>No records found</Label> </div> : null}
                {!this.state.noRecordsFound && this.state.isTextboxEmpty && this.state.isDashboardLoadingComplete ? <div style={{ display: 'block', overflow: 'auto', position: 'relative', marginTop: '2%' }}><Dashboard handleSupplierWithScopeClick={this.handleSupplierWithScopeClick} handleSupplierClick={this.handleSupplierClick} dashboardData={this.state.dashboardData} clickExampleData={this.clickExampleData} /></div> : null}
                {!this.state.noRecordsFound && this.state.isTextboxEmpty && !this.state.isDashboardLoadingComplete ? <div style={{ marginTop: '3%', marginLeft: '45%' }}> <Loader type="TailSpin" color={palette.secondary.light} height={100} width={100} /></div> : null}
                {this.state.showLoader ? <div style={{ marginTop: '3%', marginLeft: '45%' }}> <Loader type="TailSpin" color={palette.secondary.light} height={100} width={100} /></div> : null}
              </CardHeader>
              <CardBody>
                {source !== 'schwarz' &&
                  <ChangePasswordModal toggleChangePasswordModal={this.state.toggleChangePasswordModal} toggleChangePassword={this.toggleChangePassword} />
                }
                <Modal
                  isOpen={this.state.toggleXMLModal}
                  toggle={this.generateXMLContent}
                  style={{ overlay: { zIndex: 1000 } }}
                  ariaHideApp={false}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', marginTop: '-10px' }}>
                    { process.env.REACT_APP_SOURCE === 'schwarz' && <Grid container
                      direction="row"
                      justify="flex-end"
                      alignItems="center" style={{position: 'absolute', right: "100%"}}>
                      <Grid item style={{ paddingTop: '10px' }}>
                        <Select MenuProps={ {getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}} 
                        disableUnderline value={this.state.productDetailsLanguage} 
                        onChange={this.productDetailsChangeLanguageEvent}>
                        {this.productDetailsLanguageOptions()}
                        </Select>
                      </Grid>
                    </Grid> }
                    <ModalIcons 
                      t = {t}
                      setEmailSettings={this.setEmailSettings}
                      toggleTableModal={this.toggleTableModal} 
                      toggleTree={this.toggleTree}
                      toggleDocumentModal={this.toggleDocumentModal}
                      toggleImageModal={this.toggleImageModal}
                      toggleCicModal={this.toggleCicModal}
                      toggleTradeItemRecordsModal={this.toggleTradeItemRecordsModal}
                      toggleTradeItemHierarchyModal={this.toggleTradeItemHierarchyModal}
                      toggleTradeItemWithdrawalModal={this.toggleTradeItemWithdrawalModal}
                      openXmlDownloadDropdown={this.openXmlDownloadDropdown}
                      downloadJSON={this.downloadJSON}
                      downloadXML={this.downloadXML}
                      dataSource={this.state.dataSource}
                      tableModal={this.state.tableModal}
                      toggleXMLModal={this.state.toggleXMLModal}
                      treeView={this.state.treeView} 
                      documentModal={this.state.documentModal}
                      imageModal={this.state.imageModal}
                      disableDocumentButton={this.state.disableDocumentButton}
                      disableImageButton={this.state.disableImageButton} 
                      cicModal={this.state.cicModal}
                      tradeItemRecordsModal={this.state.tradeItemRecordsModal}    
                      tradeItemHierarchyModal={this.state.isTradeItemHierarchyModalOpen}     
                      tradeItemWithdrawalModal={this.state.tradeItemWithdrawalModal}  
                      isEuvino={this.state.isEuvino}    
                      isLidl={this.state.isLidl}
                      areOtherOrigions={this.state.areOtherOrigions}
                      isCicEnabled={this.context.isCicEnabled}
                      isGdsnTradeItemEnabled={this.context.isGdsnTradeItemEnabled}
                    >
                    </ModalIcons>
                    
                    <Menu
                      id="downloadXmlMenu"
                      getContentAnchorEl={null}
                      anchorEl={this.state.anchorElXmlDownload}
                      open={Boolean(this.state.anchorElXmlDownload)}
                      onClose={this.closeXmlDownloadDropdown}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                      keepMounted
                      >
                      { this.state.records !==null && this.state.records.length > 0 && <MenuItemComponent
                        product={this.state.records[this.state.index]}
                        recordIndex={this.state.index}
                        downloadCIN={this.downloadCIN}
                        downloadInformaticaXML={this.downloadInformaticaXML}
                        downloadKauflandArticlepassMoreButton={this.downloadKauflandArticlepassMoreButton}
                        handleSnackBarOpen={this.handleSnackBarOpen}
                        downloadOriginalMessage={this.downloadOriginalMessage}
                      >  
                      </MenuItemComponent> }
                    </Menu>
                    <Tooltip title={t('productDetailsModal.close')}>
                      <IconButton
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.generateXMLContent}
                        style={{ outline: 'none'  }}
                      >
                        <CloseRoundedIcon style={{ color: palette.warning.close, opacity: 1 }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    {
                      this.generateModal(t)
                    }
                  </div>
                </Modal>
                <ReleaseModal releaseModal={this.state.releaseModal} toggleReleaseModal={this.toggleReleaseModal} />
                <GPCModal toggleGPCModal={this.state.toggleGPCModal} toggleGPCModalSearch={this.toggleGPCModalSearch}
                  segment={this.state.segment} family={this.state.family}
                  class={this.state.class} brick={this.state.brick}
                  clickExampleData={this.clickExampleData}
                />
                {(this.state.isTradeItemHierarchyModalOpen && this.context.isTradeItemHierarchyModalEnabled) && <TradeItemHierarchyModal tradeItemHierarchyModal={this.state.isTradeItemHierarchyModalOpen} toggleTradeItemHierarchyModal={this.toggleTradeItemHierarchyModal} product={this.state.tradeItemHierarchyProduct} callSearchAPI={this.callSearchAPI} t = {t}/>}
                <DataRequestModal 
                  toggleDataRequestModal={this.state.toggleDataRequestModal} 
                  initiateDataRequestModal={this.initiateDataRequestModal} 
                  selectedItems={this.state.schwarzSelectedItemList} 
                  ekgCodeList={ekgCodeList} 
                  apiService={this.context.apiService} 
                  handleDataRequestModalClose={this.handleDataRequestModalClose} 
                  language={this.state.language}
                  countryCode = {this.state.markantFormCountryCode}/>
                {this.context.isSubscriptionEnabled ? <GdsnSubscriptionModal isGdsnSubscriptionModalDisplayed={this.state.isGdsnSubscriptionModalDisplayed} toggleGdsnSubscriptionModal={this.toggleGdsnSubscriptionModal} specifyQuery={this.specifyQuery} /> : null}
                {this.context.isSubscriptionEnabled ? <EuvinoSubscriptionModal isEuvinoSubscriptionModalDisplayed={this.state.isEuvinoSubscriptionModalDisplayed} toggleEuvinoSubscriptionModal={this.toggleEuvinoSubscriptionModal} /> : null}
                {this.context.isExportEnabled ?
                <ExportModal toggleExportModal={this.toggleExportModal} isExportModalDisplayed={this.state.isExportModalDisplayed} apiService={this.context.apiService} targetMarketsExportModal={this.state.targetMarkets} targetMarketSwitchesExportModal={this.state.targetMarketSwitches} language={this.state.langauge} context={this.state.context}/>
                : null}
                {!this.state.isTextboxEmpty && !this.state.showLoader ?
                  <div>
                    <FilterButtons
                      gpcFilterList={this.state.gpcFilterList}
                      gpcSelectedFilterList={this.state.gpcSelectedFilterList} 
                      languageChanged={this.state.languageChanged} 
                      manufacturerFilterList={this.state.manufacturerFilterList}
                      handleGPCManufacturerFilterButtons={this.handleGPCManufacturerFilterButtons}
                    />
                  </div>
                  : null}
                  {!this.state.isTextboxEmpty && !this.state.showLoader ?
                    <div style={{ display: 'block', overflow: 'auto', position: 'relative'}}>
                      <Table hover responsive>
                        <thead className="text-primary">
                          <tr>
                            {process.env.REACT_APP_SOURCE === 'schwarz' ?
                              <th>
                                <Tooltip title="Gelistet"><Checkbox color="primary" checked={this.state.selectAllItems} onClick={() => this.selectAllCheckBoxes()} inputProps={{ 'aria-label': 'secondary checkbox' }} /></Tooltip>
                              </th>
                            : null}
                            {process.env.REACT_APP_SOURCE === 'flaschenpost' ?
                              <th>
                                <Tooltip title="Gelistet"><Checkbox checked={this.state.selectAllItems} disabled={this.state.isUserListingReadOnly} color="primary" onClick={() => this.listAllItems()} inputProps={{ 'aria-label': 'secondary checkbox' }} /></Tooltip>
                              </th>
                            : null}
                            {process.env.REACT_APP_SOURCE === 'radeberger' ?
                              <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}></th>
                            : null}
                            <th className='text-center' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem', width: '8%'}}>
                              {process.env.REACT_APP_SOURCE === 'schwarz' ? t('searchResults.assets') : t('searchResults.image')}
                            </th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>
                              {process.env.REACT_APP_SOURCE === 'schwarz' ? "GTIN/"+t('searchResults.material') : "GTIN"}
                            </th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.brand')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.itemDescription')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.name')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>GPC</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.manufacturer/Supplier')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.packagingUnit')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.targetMarket')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.updatedAt')}</th>
                            <th className='text-left' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.9rem' }}>{t('searchResults.dataSource')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.renderTable(t)
                          }
                        </tbody>
                      </Table>
                    </div>
                    : null}
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    {!this.state.isTextboxEmpty && this.state.total > 0 ? (
                      <Grid container style={{bottom: "0px", position: 'fixed', width: '98%', background: '#ffffff', marginLeft: '-14px'}}>
                        <Grid item xs className="text-left">
                          <div style={{paddingTop: '10px', paddingLeft: '10px'}}>
                            {t('searchResults.showing')} {this.state.offset + 1} {t('searchResults.to')} {(parseInt(this.state.size) + parseInt(this.state.offset) < parseInt(this.state.total)) ? parseInt(this.state.size) + parseInt(this.state.offset) : this.state.total} {t('searchResults.of')}
                            {this.state.total === 10000 ? ` ${t('searchResults.above')}` : '' } {this.state.total} {t('searchResults.entries')}
                          </div>
                        </Grid>
                        <Grid item xs className="text-right">
                          <Pagination variant="outlined" color="primary"
                            page={this.state.pageNumber}
                            limit={this.state.size}
                            offset={this.state.offset}
                            total={this.state.total}
                            onClick={(e, offset, page) => this.handlePageClick(offset, page)}
                            size='large'
                          />
                        </Grid>
                      </Grid>
                      ) : null}
                  </MuiThemeProvider>
              </CardBody>
              {this.footer()}
            </Card>
          </Col>
        </div>
      </MuiThemeProvider>
      </>
    );
  }
}


// export default withRouter(Products);
export default compose(
  withTranslation(), // note the `()`
  withRouter,
)(Products)
export {palette}
